import { Auth, API } from 'aws-amplify';
import { Buffer } from 'buffer';

import { ApiSharedSnippetsFetch } from '../ApiSharedSnippets';
import { event } from '../../utils/ga-utils';
import { validateGuid } from '../../utils/guid-utils';
import { calculateValidity } from '../../utils/utils';

export const SHAREDSNIPPET_SAVEPREPARE = 'SHAREDSNIPPET_SAVEPREPARE';
export const SHAREDSNIPPET_SAVESTART = 'SHAREDSNIPPET_SAVESTART';
export const SHAREDSNIPPET_SAVEERROR = 'SHAREDSNIPPET_SAVEERROR';
export const SHAREDSNIPPET_SAVED = 'SHAREDSNIPPET_SAVED';

export const SHAREDSNIPPET_LOADSTART = 'SHAREDSNIPPET_LOADSTART';
export const SHAREDSNIPPET_LOADERROR = 'SHAREDSNIPPET_LOADERROR';
export const SHAREDSNIPPET_LOADED = 'SHAREDSNIPPET_LOADED';

export const SHAREDSNIPPET_REMOVESTART = 'SHAREDSNIPPET_REMOVESTART';
export const SHAREDSNIPPET_REMOVEERROR = 'SHAREDSNIPPET_REMOVEERROR';
export const SHAREDSNIPPET_REMOVED = 'SHAREDSNIPPET_REMOVED';

export function ApiSharedSnippetSavePrepare(payload) {
  return {
    type: SHAREDSNIPPET_SAVEPREPARE,
    payload,
  };
}
export function ApiSharedSnippetSaveStart(payload) {
  return {
    type: SHAREDSNIPPET_SAVESTART,
    payload,
  };
}
export function ApiSharedSnippetLoadStart(payload) {
  return {
    type: SHAREDSNIPPET_LOADSTART,
    payload,
  };
}
export function ApiSharedSnippetRemoveStart(payload) {
  return {
    type: SHAREDSNIPPET_REMOVESTART,
    payload,
  };
}
export function ApiSharedSnippetSaveError(payload) {
  return {
    type: SHAREDSNIPPET_SAVEERROR,
    payload,
  };
}
export function ApiSharedSnippetLoadError(payload) {
  return {
    type: SHAREDSNIPPET_LOADERROR,
    payload,
  };
}
export function ApiSharedSnippetRemoveError(payload) {
  return {
    type: SHAREDSNIPPET_REMOVEERROR,
    payload,
  };
}
export function ApiSharedSnippetSaved(payload) {
  return {
    // payload should contain configId
    type: SHAREDSNIPPET_SAVED,
    payload,
  };
}
export function ApiSharedSnippetLoaded(payload) {
  return {
    type: SHAREDSNIPPET_LOADED,
    payload,
  };
}
export function ApiSharedSnippetRemoved(payload) {
  return {
    type: SHAREDSNIPPET_REMOVED,
    payload,
  };
}

export function ApiSharedSnippetSave(payload) {
  return function disp(dispatch) {
    // fetch starting
    dispatch(ApiSharedSnippetSaveStart(payload));

    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/sharedsnippets';

    const buff = Buffer.from(payload.configRaw);
    const encoded = buff.toString('base64');

    const myInit = {
      body: {
        sharedSnippetId: payload.sharedSnippetId,
        content: encoded,
        filename: payload.filename,
        datecreated: Date.now(),
        userId: Auth.userId,
        datevalid: calculateValidity(Date.now(), payload.shareValidity),
        shareDisableAnalysis: payload.shareDisableAnalysis,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };

    API.post(apiName, path, myInit)
      .then((response) => {
        // response should contain configId
        event(
          'User',
          'SharedSnippetSave',
          payload.auth.username.replace('@', '_'),
        );
        dispatch(
          ApiSharedSnippetSaved({
            sharedSnippetId: payload.sharedSnippetId,
            response,
          }),
        );
      })
      .catch((error) => {
        event('Error', 'SharedSnippetSave', error.response);
        dispatch(ApiSharedSnippetSaveError(error.response));
      });
  };
}
export function ApiSharedSnippetRemove(payload) {
  return function disp(dispatch) {
    dispatch(ApiSharedSnippetRemoveStart(payload));

    const { sharedSnippetId } = payload;

    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/sharedsnippets/${sharedSnippetId}`;
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    API.del(apiName, path, myInit)
      .then((response) => {
        event(
          'User',
          'SharedSnippetRemove',
          payload.auth.username.replace('@', '_'),
        );
        response.data.sharedSnippetId = sharedSnippetId;

        dispatch(ApiSharedSnippetRemoved(response.data));
        dispatch(ApiSharedSnippetsFetch(true));
      })
      .catch((error) => {
        event('Error', 'SharedSnippetRemove', error.response);
        console.error(error.response);
        dispatch(ApiSharedSnippetRemoveError(error.response));
      });
  };
}

export function ApiSharedSnippetLoad(payload, parse) {
  return function disp(dispatch) {
    // fetch starting
    dispatch(ApiSharedSnippetLoadStart(payload));

    const { sharedSnippetId } = payload;

    if (!validateGuid(sharedSnippetId)) {
      event('Error', 'SharedSnippetLoad', 'Invalid Snippet ID');
      dispatch(ApiSharedSnippetLoadError('Invalid Snippet'));
      return;
    }

    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/sharedsnippets/${sharedSnippetId}`;
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    API.get(apiName, path, myInit)
      .then((response) => {
        event('Config', 'SharedSnippetLoad', sharedSnippetId);
        const buff = Buffer.from(response.data.content, 'base64');
        const decoded = buff.toString('ascii');

        response.data.sharedSnippetId = sharedSnippetId;

        parse(sharedSnippetId, decoded);
        dispatch(ApiSharedSnippetLoaded(response.data));
      })
      .catch((error) => {
        event(
          'Error',
          'SharedSnippetLoad',
          JSON.stringify(error.response.data.content),
        );
        dispatch(ApiSharedSnippetLoadError(error.response.data.content));
      });
  };
}
