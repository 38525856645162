import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { generateDoc } from '../../../actions/healthcheck/generateDoc';
import { clearHealthcheck } from '../../../actions/healthcheck/clear';

import { event } from '../../../utils/ga-utils';

const mapStateToProps = (state) => ({
  inputs: state.healthcheck.inputs,
  auth: state.auth,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ generateDoc, clearHealthcheck }, dispatch);
}

class Buttons extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  handleSubmit(e) {
    const { generateDoc: generateDocFn, auth } = this.props;
    event('User', 'Healthcheck Report', auth.username.replace('@', '_'));
    generateDocFn(true);
    e.preventDefault();
  }

  handleClear(e) {
    const { clearHealthcheck: clearHealthcheckFn } = this.props;
    clearHealthcheckFn(true);
    e.preventDefault();
  }

  render() {
    return (
      <div
        className="buttons"
        style={{
          display: 'inline-block',
          textAlign: 'center',
        }}
      >
        <form>
          <input
            type="submit"
            value="Generate .docx"
            onClick={this.handleSubmit}
            style={{
              textAlign: 'center',
              height: '40px',
              width: '140px',
              margin: '5px',
              backgroundColor: '#91CDD4',
              border: '1px solid #ccc',
              color: '#fff',
              fontSize: '18px',
            }}
          />

          <input
            type="submit"
            value="Clear"
            onClick={this.handleClear}
            style={{
              textAlign: 'center',
              height: '40px',
              width: '140px',
              margin: '5px',
              backgroundColor: '#535353',
              border: '1px solid #ccc',
              color: '#fff',
              fontSize: '18px',
            }}
          />
        </form>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);
