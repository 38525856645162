import React, { Component } from 'react';
import DropTemplate from '../droptemplate';

class TemplateSelect extends Component {
  render() {
    const { template } = this.props;
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {template.file ? (
          <h5>Template File: {template.file}</h5>
        ) : (
          <DropTemplate />
        )}
      </>
    );
  }
}

export default TemplateSelect;
