import { API } from 'aws-amplify';

export const TEMPLATES_RETRIEVED = 'TEMPLATES_RETRIEVED';
export const TEMPLATES_RETRIEVING = 'TEMPLATES_RETRIEVING';

export function ApiTemplatesRetrieved(payload) {
  return {
    type: TEMPLATES_RETRIEVED,
    payload,
  };
}
export function ApiTemplatesRetrieving(payload) {
  return {
    type: TEMPLATES_RETRIEVING,
    payload,
  };
}

export function ApiTemplatesFetch(payload) {
  return function disp(dispatch) {
    // fetch starting
    dispatch(ApiTemplatesRetrieving(payload));

    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/templates';
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    API.get(apiName, path, myInit)
      .then((response) => {
        dispatch(ApiTemplatesRetrieved(response.data));
      })
      .catch((error) => {
        console.error(error.response);
      });
  };
}
