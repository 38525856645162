import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
  root: {
    flex: 1,
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit(2),
    textAlign: 'center',
    background: 'none',
    color: 'white',
    boxShadow: 'none',
  },
});

class AboutPage extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="homepage">
        <h2>Xpand Config Analyzer</h2>
        <Container>
          <p>
            Xpand Config Analyzer is a web application to enable Citrix
            NetScaler admins to enhance the implementation and configuration
            changes on a NetScaler environment. The main goal is to empower the
            community of Citrix NetScaler administrators and consultants, and
            eventually save precious time.
          </p>
          <Grid container spacing={24}>
            <Grid item xs>
              <Paper className={classes.paper}>
                <p>
                  <img
                    src="/img/001-processing.png"
                    width="120"
                    alt="processing"
                  />
                </p>
                <p>
                  <h5>Filtering</h5>
                </p>
                <p>
                  Loading and filtering config files is easy. Just drag and drop
                  - or use the upload button - to load a config file. X-Config
                  will automatically load in all application dependencies and
                  colors different syntax and objects to make it easy to
                  understand the config hierarchy. By using the filter options,
                  you can extract application logic.
                </p>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper}>
                <p>
                  <img src="/img/003-maximize.png" width="120" alt="maximize" />
                </p>
                <p>
                  <h5>Save - Share - Export</h5>
                </p>
                <p>
                  Until you Save a configuration, nothing is stored outside your
                  browser. Optionally a config file can be uploaded, tagged and
                  saved into a personal vault. Saved configurations can be
                  exported or copy-pasted to immediately apply on a running
                  NetScaler instance.
                </p>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper}>
                <p>
                  <img src="/img/002-coding.png" width="120" alt="roadmap" />
                </p>
                <p>
                  <h5>Roadmap</h5>
                </p>
                <p>
                  We are looking for user feedback to help shape the roadmap,
                  dont hesitate to open a ticket with your feature requests. A
                  glimpse into our roadmap for now: Organize your configs into
                  teams, creating code snippets based on templates..
                </p>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <Paper className="aboutArrow">
          <p>
            <h4>About Arrow Xpand</h4>
          </p>
          <p>
            Arrow Xpand is a managed services portfolio brought to our partners,
            integrators and customers.{' '}
            <a
              href="https://arrow.com/ecs/xpand"
              target="_blank"
              rel="noreferrer"
            >
              Learn more..
            </a>
          </p>
          <br />
        </Paper>
      </div>
    );
  }
}
AboutPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(AboutPage));
