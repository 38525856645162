const calculateValidity = (inputDate, validityWeeks) => {
  // Add time to inputDate depending on validityWeeks
  // If validityWeeks is 0, it is unlimited and we set 2099
  if (!validityWeeks) return Date.parse(new Date(2099, 1, 1));
  if (validityWeeks === 0) return Date.parse(new Date(2099, 1, 1));
  const date = new Date(inputDate);
  date.setDate(date.getDate() + validityWeeks * 7);
  return Date.parse(date);
};

export { calculateValidity };
