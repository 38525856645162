import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';

// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'babel-polyfill';
import Amplify, { Auth } from 'aws-amplify';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { unregister } from './registerServiceWorker';
import App from './App';
import { initGA } from './utils/ga-utils';
import { store, persistor } from './store';
import { ParserContext } from './ParserContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#22252a',
      light: '#6d6d6d',
      dark: '#22252a',
      // contrastText: '#fff',
    },
    type: 'dark',
  },
  typography: {
    fontFamily: [
      'Arrow Display',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'Lucida Grande',
    ].join(','),
  },
});

// const persistConfig = {
//   key: 'root',
//   storage,
// };

const oauth = {
  domain: process.env.REACT_APP_OAUTH_DOMAIN,
  scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  responseType: 'code',
  redirectSignIn: process.env.REACT_APP_OAUTH_SIGNIN,
  redirectSignOut: process.env.REACT_APP_OAUTH_SIGNOUT,
};

const settings = {
  profileversion: process.env.REACT_APP_AUTH_PROFILEVERSION,
};

// Amplify.Logger.LOG_LEVEL = 'DEBUG';
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AUTH_IDENTITYPOOLID,
    region: process.env.REACT_APP_COMMON_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USERPOOLWEBCLIENTID,
    mandatorySignIn: false,
    oauth,
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_API_NAME,
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: process.env.REACT_APP_COMMON_REGION,
      },
    ],
  },
  Analytics: {
    disabled: true,
  },
});
// eslint-disable-next-line no-unused-vars
const currentConfig = Auth.configure();

const addJiraWidget = (callback) => {
  const jhdScript = document.createElement('script');
  jhdScript.type = 'text/javascript';
  jhdScript.setAttribute('data-jsd-embedded', null);
  jhdScript.setAttribute('data-key', '5dab6fc7-b9b2-4c08-a5f3-4dff47518a1a');
  jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
  jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
  if (jhdScript.readyState) {
    // old IE support
    jhdScript.onreadystatechange = () => {
      if (
        jhdScript.readyState === 'loaded' ||
        jhdScript.readyState === 'complete'
      ) {
        jhdScript.onreadystatechange = null;
        callback();
      }
    };
  } else {
    // modern browsers
    jhdScript.onload = () => {
      callback();
    };
  }
  document.getElementsByTagName('head')[0].appendChild(jhdScript);
};

const handleAcceptCookie = () => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    // eslint-disable-next-line no-console
    initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  }
  // load Jira SD Widget
  addJiraWidget(() => {
    const DomContentLoadedEvent = document.createEvent('Event');
    DomContentLoadedEvent.initEvent('DOMContentLoaded', true, true);
    window.document.dispatchEvent(DomContentLoadedEvent);
  });
};
const handleOverlayClick = () => {
  // focus on the cookie consent popup
};

// const CookieAcceptButton = () => {

// }

class Root extends Component {
  render() {
    const test = getCookieConsentValue();
    // eslint-disable-next-line no-console
    if (test === 'true') handleAcceptCookie();
    // const { highlight } = this.state;
    return (
      <Provider store={store}>
        <ParserContext>
          <PersistGate loading={null} persistor={persistor}>
            <MuiThemeProvider theme={theme}>
              <App settings={settings} />
            </MuiThemeProvider>
            <CookieConsent
              overlay
              enableDeclineButton
              onAccept={handleAcceptCookie}
              onOverlayClick={handleOverlayClick}
              buttonText="I accept"
              style={{
                height: '150px',
                width: '98%',
                border: '2px solid #91CDD4',
                alignItems: 'center',
                position: 'absolute',
                top: '45%',
              }}
              buttonStyle={{
                height: '60px',
                width: '160px',
                backgroundColor: '#91CDD4',
                borderRadius: '7px',
                fontSize: 16,
              }}
              declineButtonStyle={{
                height: '60px',
                width: '160px',
                backgroundColor: '#171A1D',
                color: '#fff',
                borderRadius: '7px',
                fontSize: 16,
              }}
              overlayStyle={{
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                zIndex: '999',
                backgroundColor: 'rgba(0,0,0,0.7)',
              }}
            >
              This website uses cookies to customize content and analyze
              traffic. Please click on the button below to give your consent.
            </CookieConsent>
          </PersistGate>
        </ParserContext>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.querySelector('#root'));
// registerServiceWorker();
unregister();
