/* eslint-disable no-console */
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import PublishIcon from '@material-ui/icons/Publish';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { showAnalysis } from '../../actions/showAnalysis';
import { withParser } from '../../ParserContext';
import { event } from '../../utils/ga-utils';

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAnalysis,
    },
    dispatch,
  );

class DropFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // loaded: props.file,
      // files: [],
      alert: {
        text: 'Start now by loading ns.conf file',
        type: '',
      },
    };

    this.onDrop = this.onDrop.bind(this);
    // this.renderFileList = this.renderFileList.bind(this);
  }

  onDrop = (acceptedFiles) => {
    const {
      onUpload: onUploadAlt,
      showAnalysis: showAnalysisAlt,
      parse: parseAlt,
      auth,
    } = this.props;

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('Reading aborted');
      reader.onerror = (error) => console.log('file read error: ', error);
      reader.onload = () => {
        const contents = reader.result;
        parseAlt(file.name, contents);
        const username = auth.username ?? 'anonymous';
        event('Config', 'Parse', username.replace('@', '_'));
        showAnalysisAlt(true);
        onUploadAlt(); // closes the load popup
      };
      reader.readAsText(file);
    });
  };
  // const toLoad = acceptedFiles;

  // function htGet(blob) {
  //   const xmlHttp = new XMLHttpRequest();
  //   xmlHttp.open('GET', blob, false);
  //   xmlHttp.send(null);
  //   return xmlHttp.responseText;
  // }
  // const long = JSON.stringify(toLoad[0].preview).length - 1;
  // const loadStr = JSON.stringify(toLoad[0].preview).substring(1, long);
  // const blobFile = htGet(loadStr);

  // parseAlt(acceptedFiles[0].name, blobFile);
  // const username = auth.username ?? 'anonymous';
  // event('Config', 'Parse', username.replace('@', '_'));
  // showAnalysisAlt(true);
  // onUploadAlt(); // closes the load popup

  // renderFileList = () => {
  //   const { files, alert } = this.state;
  //   if (files.length > 0) {
  //     return (
  //       <div>
  //         {files.map((f) => (
  //           <li>
  //             {f.name} - {f.size} bytes
  //           </li>
  //         ))}
  //       </div>
  //     );
  //   }
  //   return (
  //     <div className="dropzone-inner">
  //       <p>{alert.text}</p>
  //       {/* <Icon large>file_upload</Icon> */}
  //       <PublishIcon style={{ fontSize: '4rem' }} />
  //     </div>
  //   );
  // };

  render() {
    const { alert } = this.state;
    return (
      <div className="dropzone">
        <Dropzone
          id="dropzone"
          className="home-drop"
          accept=".txt,.conf"
          multiple={false}
          disablePreview={false}
          onDrop={this.onDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div className="home-drop" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dropzone-inner">
                  <p>{alert.text}</p>
                  {/* <Icon large>file_upload</Icon> */}
                  <PublishIcon style={{ fontSize: '4rem' }} />
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withParser(DropFile)),
);
