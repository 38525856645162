import {
  GUI_TOOLBAR_ADDFILTER_PREPARE,
  GUI_TOOLBAR_ADDFILTER_DONE,
  GUI_SET_PASSWORDRESETUSERNAME,
  GUI_SET_REGISTERUSERNAME,
} from '../../actions/gui';

const initialState = {
  activeToolbar: 'filter',
  addFilterPreparing: false,
  currentPath: '',
  passwordresetUsername: '',
  registerUsername: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GUI_TOOLBAR_ADDFILTER_PREPARE:
      return { ...state, addFilterPreparing: true };

    case GUI_TOOLBAR_ADDFILTER_DONE:
      return { ...state, addFilterPreparing: false };

    case GUI_SET_PASSWORDRESETUSERNAME:
      return { ...state, passwordresetUsername: action.payload };

    case GUI_SET_REGISTERUSERNAME:
      return { ...state, registerUsername: action.payload };

    default:
      return state;
  }
}
