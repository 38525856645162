/* jshint esversion: 9 */

import {
  CONFIGS_RETRIEVING,
  CONFIGS_RETRIEVED,
} from '../../actions/ApiConfigs';
import {
  CONFIG_SAVED,
  CONFIG_SAVESTART,
  CONFIG_SAVEERROR,
  CONFIG_SAVEPREPARE,
  CONFIG_LOADED,
  CONFIG_LOADSTART,
  CONFIG_LOADERROR,
} from '../../actions/ApiConfig';

const initialState = {
  isConfigsRetrieving: false,
  configs: [],
  isConfigSavePreparing: false,
  isConfigSaved: false,
  activeconfigid: null,
  isConfigLoaded: false,
  isError: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONFIGS_RETRIEVED:
      // list of configs for current user is fetched
      return { ...state, isConfigsRetrieving: false, configs: action.payload };
    case CONFIGS_RETRIEVING:
      // beginning of fetch configs list
      return { ...state, isConfigsRetrieving: true };
    case CONFIG_SAVED:
      // config saved by user, set activeconfigid
      return {
        ...state,
        activeconfigid: action.payload.configId,
        isConfigActivity: false,
        isConfigSavePreparing: false,
        isConfigSaved: true,
      };
    case CONFIG_LOADED:
      // config loaded by user, set activeconfigid
      return {
        ...state,
        activeconfigid: action.payload.configId,
        isConfigActivity: false,
        isConfigLoaded: true,
      };
    case CONFIG_SAVESTART:
      return {
        ...state,
        isConfigActivity: true,
        isConfigSaved: false,
      };
    case CONFIG_SAVEERROR:
      return {
        ...state,
        isConfigActivity: false,
        isConfigSaved: false,
        isError: true,
        errorMessage: action.payload,
      };
    case CONFIG_LOADSTART:
      return {
        ...state,
        isConfigActivity: true,
        isConfigLoaded: false,
      };
    case CONFIG_LOADERROR:
      return {
        ...state,
        isConfigActivity: false,
        isConfigSaved: false,
        isError: true,
        errorMessage: action.payload,
      };
    case CONFIG_SAVEPREPARE:
      return {
        ...state,
        isConfigSavePreparing: true,
        isConfigSaved: false,
      };
    default:
      return state;
  }
};
