import React, { Component } from 'react';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { updateFilter } from '../../actions/updateFilter';
import { setActiveLine } from '../../actions/setActiveLine';
import { withParser } from '../../ParserContext';

require('./netscaler');
require('codemirror/lib/codemirror.css');
require('../../style/monokai.css');

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateFilter, setActiveLine }, dispatch);
}

const mapStateToProps = () => ({});

class CodeEditor extends Component {
  constructor(props) {
    super(props);
    this.onDependencyClick = this.onDependencyClick.bind(this);

    this.handleClick = this.handleClick.bind(this);

    this.state = {
      mode: props.mode,
      // file: localStorage.getItem("file")
    };
  }

  // eslint-disable-next-line react/sort-comp
  handleClick(e, data) {
    const {
      addObjectTypeFilter: addObjectTypeFilterAlt,
      addDependencyFilter: addDependencyFilterAlt,
    } = this.props;
    if (data.menu === 'findobjects') {
      addObjectTypeFilterAlt();
    } else if (data.menu === 'finddependencies') {
      addDependencyFilterAlt();
    }
  }

  componentDidMount() {
    // load snippet based on argument
    const { mode } = this.state;
    const {
      id,
      ApiSharedSnippetLoad: ApiSharedSnippetLoadAlt,
      parse,
    } = this.props;
    if (mode === 'snippet') {
      const sharedSnippetId = id;
      // retrieve snippet from api
      ApiSharedSnippetLoadAlt({ sharedSnippetId }, parse);
    }
  }

  onDependencyClick(objecttype, name) {
    const { updateFilter: updateFilterAlt } = this.props;
    // filter dependencies
    updateFilterAlt(`${objecttype}:${name}`);
  }

  render() {
    const { code, uid, setActiveLine: setActiveLineAlt } = this.props;

    if (code) {
      return (
        <div>
          <ContextMenuTrigger id={uid}>
            <CodeMirror
              value={code}
              height="100%"
              options={{
                mode: 'netscaler',
                theme: 'monokai',
                lineNumbers: 'true',
                readOnly: true,
                resetSelectionOnContextMenu: true,
                scrollbarStyle: null,
              }}
              onContextMenu={(editor, event) => {
                //  var editor = window.$('.CodeMirror')[0].CodeMirror;
                const coords = editor.coordsChar({
                  left: event.pageX,
                  top: event.pageY,
                });
                setActiveLineAlt(editor.getLine(coords.line));
              }}
            />
          </ContextMenuTrigger>
          <ContextMenu id={uid}>
            <MenuItem data={{ menu: 'findobjects' }} onClick={this.handleClick}>
              Find objects like this
            </MenuItem>
            <MenuItem
              data={{ menu: 'finddependencies' }}
              onClick={this.handleClick}
            >
              Find Dependencies
            </MenuItem>
          </ContextMenu>
        </div>
      );
    }
    return <code />;
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withParser(CodeEditor));
