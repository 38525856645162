import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  disableGlobal: true,
});

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '120px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    border: '0px solid #757575',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  critical: {
    color: '#E03825',
    width: '25%',
    height: '120px',
    minWidth: 'auto',
    background: '#31353E',
    border: '2px solid #464C54',
    margin: '5px',
  },
  warning: {
    color: '#E57630',
    width: '25%',
    height: '120px',
    minWidth: 'auto',
    background: '#31353E',
    border: '2px solid #464C54',
    margin: '5px',
  },
  medium: {
    color: '#F1BE42',
    width: '25%',
    height: '120px',
    minWidth: 'auto',
    background: '#31353E',
    border: '2px solid #464C54',
    margin: '5px',
  },
  low: {
    color: '#89C0C8',
    width: '25%',
    height: '120px',
    minWidth: 'auto',
    background: '#31353E',
    border: '2px solid #464C54',
    margin: '5px',
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  description: {
    color: '#ccc',
    fontSize: 16,
    textTransform: 'none',
  },
  counter: {
    fontSize: 44,
    fontWeight: 'bold',
  },
}));

export const CRITICAL = 'critical';
export const WARNING = 'warning';
export const MEDIUM = 'medium';
export const LOW = 'low';

function Analysis(props) {
  const classes = useStyles();
  const { analysisItems } = props;

  let criticalItems = [];
  let majorItems = [];
  let mediumItems = [];
  let lowItems = [];

  if (analysisItems) {
    criticalItems = analysisItems
      .filter((x) => x.matched)
      .filter((x) => x.rule.severity === 1);
    majorItems = analysisItems
      .filter((x) => x.matched)
      .filter((x) => x.rule.severity === 2);
    mediumItems = analysisItems
      .filter((x) => x.matched)
      .filter((x) => x.rule.severity === 3);
    lowItems = analysisItems
      .filter((x) => x.matched)
      .filter((x) => x.rule.severity === 4 || x.rule.severity === 5);
  }

  return (
    <StylesProvider generateClassName={generateClassName}>
      <div className={classes.root}>
        <Button
          role={CRITICAL}
          disableRipple
          disableFocusRipple
          disabled={criticalItems.length === 0}
          disableElevation
          classes={{ root: classes.critical, label: classes.label }}
        >
          <div className={classes.description}>Critical</div>
          <div className={classes.counter}>{criticalItems.length}</div>
        </Button>
        <Button
          role={WARNING}
          disableRipple
          disableFocusRipple
          disabled={majorItems.length === 0}
          disableElevation
          classes={{ root: classes.warning, label: classes.label }}
        >
          <div className={classes.description}>Major</div>
          <div className={classes.counter}>{majorItems.length}</div>
        </Button>
        <Button
          role={MEDIUM}
          disableRipple
          disableFocusRipple
          disabled={mediumItems.length === 0}
          disableElevation
          classes={{ root: classes.medium, label: classes.label }}
        >
          <div className={classes.description}>Medium</div>
          <div className={classes.counter}>{mediumItems.length}</div>
        </Button>
        <Button
          role={LOW}
          disableRipple
          disableFocusRipple
          disabled={lowItems.length === 0}
          disableElevation
          classes={{ root: classes.low, label: classes.label }}
        >
          <div className={classes.description}>Low</div>
          <div className={classes.counter}>{lowItems.length}</div>
        </Button>
      </div>
    </StylesProvider>
  );
}

export default Analysis;
