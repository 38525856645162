/* eslint-disable default-param-last */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { showAnalysis } from '../../actions/showAnalysis';
import MyTreeView from '../mytreeview';
import { updateFilter } from '../../actions/updateFilter';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateFilter, showAnalysis }, dispatch);
}

const mapStateToProps = (state) => ({
  configParsed: state.config.configParsed,
  analysisVisible: state.config.analysisVisible,
  analysisEnabled: state.config.analysisEnabled,
  analysisItems: state.config.analysisItems,
});

const styles = {
  paperroot: {
    backgroundColor: 'unset',
  },
  expansionpanel: {
    position: 'unset',
  },
  panelsummary: {
    padding: 0,
    marginLeft: 8,
  },
  listitemdisabled: {
    marginLeft: 8,
  },
  buttondisabled: {
    marginLeft: 8,
  },
  text: {
    paddingRight: '5px',
  },
};

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.onUpdateFilter = this.onUpdateFilter.bind(this);
    this.onAnalysisClick = this.onAnalysisClick.bind(this);
  }

  onAnalysisClick() {
    const { showAnalysis: showAnalysisAlt } = this.props;
    showAnalysisAlt('');
  }

  onUpdateFilter(nodeobjecttype) {
    const { analysisVisible, updateFilter: updateFilterAlt } = this.props;
    if (nodeobjecttype) {
      if (nodeobjecttype === 'clear') {
        if (analysisVisible) {
          const newfilter = [];
          updateFilterAlt({ replace: true, newfilter });
        }
      } else {
        const newfilter = {};
        newfilter.filtertype = 'type';
        newfilter.filtervalue = nodeobjecttype;
        updateFilterAlt({ replace: true, newfilter });
      }
    }
  }

  addToCategories(categories, toAdd = [], objecttype) {
    // toAdd is array like System\Network\IP , objecttype is nsip
    // last item can be leaf or parent

    if (toAdd.length > 0) {
      // add item
      const newchild = {};

      if (toAdd.length === 1) {
        // processing last item in array, set objecttype for click
        newchild.objecttype = objecttype;
      } else {
        newchild.objecttype = null;
      }

      newchild.value = toAdd[0];
      newchild.nodes = [];

      // check if already child with correct name
      let existing = categories.nodes.find(
        (item) => item.value === newchild.value,
      );

      if (existing) {
        // node exists. If this is last item in array, set objecttype
        // on existing node
        if (toAdd.length === 1) {
          if (existing.objecttype === null) {
            existing.objecttype = objecttype;
          }
        }
        toAdd.shift();
        existing = this.addToCategories(existing, toAdd, objecttype);
      } else {
        // node does not exist, add to existing
        // eslint-disable-next-line no-lonely-if
        if (newchild.value !== '_Unmatched') {
          const newlength = categories.nodes.push(newchild);
          toAdd.shift();
          // eslint-disable-next-line no-param-reassign
          categories.nodes[newlength - 1] = this.addToCategories(
            categories.nodes[newlength - 1],
            toAdd,
            objecttype,
          );
        }
      }
    } else {
      // to Add is empty, do nothing
    }

    return categories;
  }

  render() {
    const { classes, configParsed, analysisItems, analysisEnabled } =
      this.props;
    let menu2;
    let categories = {
      objecttype: 'root',
      value: 'root',
      // children: [
      nodes: [
        {
          objecttype: null,
          value: 'System',
          nodes: [
            {
              objecttype: null,
              value: 'Settings',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'High Availability',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'NTP Servers',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Profiles',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'User Administration',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Auditing',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'SNMP',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Cluster',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Network',
              nodes: [],
            },
          ],
        },
        {
          objecttype: null,
          value: 'AppExpert',
          nodes: [
            {
              objecttype: null,
              value: 'HTTP Callouts',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Pattern Sets',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Data Sets',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'String Maps',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Location',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'NS Variables',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'NS Assignments',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Expressions',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Rate Limiting',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Action Analytics',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'AppQoE',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Rewrite',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Responder',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Spillover',
              nodes: [],
            },
          ],
        },
        {
          objecttype: null,
          value: 'Traffic Management',
          nodes: [
            {
              objecttype: null,
              value: 'Load Balancing',
              nodes: [
                {
                  objecttype: null,
                  value: 'Virtual Servers',
                  nodes: [],
                },
                {
                  objecttype: null,
                  value: 'Services',
                  nodes: [],
                },
                {
                  objecttype: null,
                  value: 'Service Groups',
                  nodes: [],
                },
                {
                  objecttype: null,
                  value: 'Monitors',
                  nodes: [],
                },
                {
                  objecttype: null,
                  value: 'Metric Tables',
                  nodes: [],
                },
                {
                  objecttype: null,
                  value: 'Servers',
                  nodes: [],
                },
                {
                  objecttype: null,
                  value: 'Persistency Groups',
                  nodes: [],
                },
              ],
            },
            {
              objecttype: null,
              value: 'Content Switching',
              nodes: [
                {
                  objecttype: null,
                  value: 'Virtual Servers',
                  nodes: [],
                },
                {
                  objecttype: null,
                  value: 'Policy Labels',
                  nodes: [],
                },
                {
                  objecttype: null,
                  value: 'Policies',
                  nodes: [],
                },
                {
                  objecttype: null,
                  value: 'Actions',
                  nodes: [],
                },
              ],
            },
            {
              objecttype: null,
              value: 'Cache Redirection',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'DNS',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'GSLB',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'SSL',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Spillover',
              nodes: [],
            },
          ],
        },
        {
          objecttype: null,
          value: 'Optimization',
          nodes: [
            {
              objecttype: null,
              value: 'HTTP Compression',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Integrated Caching',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Front End Optimization',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Content Accelerator',
              nodes: [],
            },
          ],
        },
        {
          objecttype: null,
          value: 'Security',
          nodes: [
            {
              objecttype: null,
              value: 'DNS Security',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'AAA - Application Traffic',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Application Firewall',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Reputation',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Protection Features',
              nodes: [],
            },
          ],
        },
        {
          objecttype: null,
          value: 'NetScaler Gateway',
          nodes: [
            {
              objecttype: null,
              value: 'Global Settings',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Virtual Servers',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Portal Themes',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Policies',
              nodes: [],
            },
            {
              objecttype: null,
              value: 'Resources',
              nodes: [],
            },
          ],
        },
      ],
    };

    if (configParsed.objects) {
      Object.getOwnPropertyNames(configParsed.objects).map(
        // eslint-disable-next-line array-callback-return
        (objecttypekey) => {
          const objecttype = configParsed.objects[objecttypekey];
          const objectcategory = objecttype.category;
          if (objectcategory) {
            const objectvisible = objecttype.visible;
            // var catsplit = makeNestedObjWithArrayItemsAsKeys(objectcategory.split("\\"));
            if (objectvisible) {
              categories = this.addToCategories(
                categories,
                objectcategory.split('\\'),
                objecttypekey,
              );
            }
          }
        },
      );

      // test to parse into new format
      const newtreeitems = categories.nodes;

      menu2 = (
        <>
          <ListItem
            key="Warnings"
            id="warnings"
            value="Warnings"
            disabled={!analysisEnabled}
            button
            onClick={this.onAnalysisClick}
            style={{ paddingLeft: '20px' }}
          >
            <div className={classes.text}>Config Analysis</div>
            {analysisEnabled && (
              <Chip
                color="secondary"
                label={analysisItems.filter((a) => a.matched).length}
                size="small"
              />
            )}
          </ListItem>
          <Divider />
          <MyTreeView
            tree={newtreeitems}
            onLeafClick={this.onUpdateFilter}
            test={configParsed.objects}
          />
        </>
      );
    } else {
      menu2 = (
        <div style={{ paddingLeft: '20px' }}>
          <br />
          &nbsp;No config loaded. <br />
          &nbsp;Click the <NoteAddIcon width="20" height="20" /> icon to load a
          configuration
        </div>
      );
    }

    return <div className="sidebar">{menu2}</div>;
  }
}
SideBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SideBar),
);
