import { API } from 'aws-amplify';

export const CONFIGS_RETRIEVED = 'CONFIGS_RETRIEVED';
export const CONFIGS_RETRIEVING = 'CONFIGS_RETRIEVING';

export function ApiConfigsRetrieved(payload) {
  return {
    type: CONFIGS_RETRIEVED,
    payload,
  };
}
export function ApiConfigsRetrieving(payload) {
  return {
    type: CONFIGS_RETRIEVING,
    payload,
  };
}

export function ApiConfigsFetch(payload) {
  return function disp(dispatch) {
    // fetch starting
    dispatch(ApiConfigsRetrieving(payload));

    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/configs';
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    API.get(apiName, path, myInit)
      .then((response) => {
        dispatch(ApiConfigsRetrieved(response.data));
      })
      .catch((error) => {
        console.error(error.response);
      });
  };
}
