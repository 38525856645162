/* eslint-disable no-console */
export const CLEAR = 'CLEAR';

export function clearHealthcheck(payload) {
  console.log('action: Clear');
  return {
    type: CLEAR,
    payload,
  };
}
