import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DropFile from '../dropfile';

const styles = {
  root: {
    flexGrow: 1,
  },
};

class LoadDialog extends React.Component {
  handleClose = () => {
    const { onClose: onCloseAlt } = this.props;
    onCloseAlt(true);
  };

  render() {
    const { ...other } = this.props;
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="Load Configuration"
        {...other}
      >
        <DialogTitle id="form-dialog-title">Load Configuration</DialogTitle>
        <DialogContent>
          <Typography>Load a config file in browser</Typography>

          <DropFile onUpload={() => this.handleClose(true)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose('cancel')} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
LoadDialog.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
};
export default withStyles(styles)(LoadDialog);
