import { API } from 'aws-amplify';

export const SETTINGS_RETRIEVED = 'SETTINGS_RETRIEVED';
export const SETTINGS_RETRIEVING = 'SETTINGS_RETRIEVING';

export function ApiSettingsRetrieved(payload) {
  return {
    type: SETTINGS_RETRIEVED,
    payload,
  };
}
export function ApiSettingsRetrieving(payload) {
  return {
    type: SETTINGS_RETRIEVING,
    payload,
  };
}

export function ApiSettingsFetch(payload) {
  return function disp(dispatch) {
    // fetch starting
    dispatch(ApiSettingsRetrieving(payload));

    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/settings';
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    API.get(apiName, path, myInit)
      .then((response) => {
        dispatch(ApiSettingsRetrieved(response.data));
      })
      .catch((error) => {
        console.error(error.response);
      });
  };
}
