import React, { Component } from 'react';

import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import ToolBar from '../../components/toolbar';
import SideBar from '../../components/sidebar';
import Viewer from '../../components/viewer';
import AnalysisViewer from '../../components/analysisViewer';

const mapStateToProps = (state) => ({
  analysisVisible: state.config.analysisVisible,
});

class SnippetPage extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    const { analysisVisible } = this.props;
    let activeControl = <Viewer mode="configs" />;

    if (analysisVisible) {
      activeControl = <AnalysisViewer />;
    }

    return (
      <Container fixed={false} maxWidth={false} disableGutters>
        <ToolBar />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          wrap="nowrap"
          spacing={0}
        >
          <Grid item xs={2} style={{ maxWidth: '340px', minWidth: '260px' }}>
            <SideBar />
          </Grid>
          <Grid
            item
            xs
            style={{ backgroundColor: '#23262B', overflow: 'hidden' }}
          >
            {activeControl}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default connect(mapStateToProps)(SnippetPage);
