import {
  TEMPLATES_RETRIEVING,
  TEMPLATES_RETRIEVED,
} from '../../actions/ApiTemplates';
import {
  TEMPLATE_SAVED,
  TEMPLATE_SAVESTART,
  TEMPLATE_SAVEPREPARE,
  TEMPLATE_LOADED,
  TEMPLATE_LOADSTART,
} from '../../actions/ApiTemplate';

const initialState = {
  isTemplatesRetrieving: false,
  templates: [],
  isTemplateSavePreparing: false,
  isTemplateActivity: false,
  isTemplateSaved: false,
  activetemplateid: null,
  isTemplateLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TEMPLATES_RETRIEVED:
      // list of configs for current user is fetched
      return {
        ...state,
        isTemplatesRetrieving: false,
        templates: action.payload,
      };
    case TEMPLATES_RETRIEVING:
      // beginning of fetch configs list
      return { ...state, isTemplatesRetrieving: true };
    case TEMPLATE_SAVED:
      // config saved by user, set activeconfigid
      return {
        ...state,
        activetemplateid: action.payload.templateId,
        isTemplateActivity: false,
        isTemplateSavePreparing: false,
        isTemplateSaved: true,
      };
    case TEMPLATE_LOADED:
      // config loaded by user, set activeconfigid
      return {
        ...state,
        activetemplateid: action.payload.templateId,
        isTemplateActivity: false,
        isTemplateLoaded: true,
      };
    case TEMPLATE_SAVESTART:
      return { ...state, isTemplateActivity: true, isTemplateSaved: false };
    case TEMPLATE_LOADSTART:
      return { ...state, isTemplateActivity: true, isTemplateLoaded: false };
    case TEMPLATE_SAVEPREPARE:
      return {
        ...state,
        isTemplateSavePreparing: true,
        isTemplateSaved: false,
      };
    default:
      return state;
  }
};
