import { API } from 'aws-amplify';
import { Buffer } from 'buffer';
import { showAnalysis } from '../showAnalysis';
import { ApiConfigsFetch } from '../ApiConfigs';
import { event } from '../../utils/ga-utils';
import { createGuid, validateGuid } from '../../utils/guid-utils';

export const CONFIG_SAVEPREPARE = 'CONFIG_SAVEPREPARE';
export const CONFIG_SAVESTART = 'CONFIG_SAVESTART';
export const CONFIG_SAVEERROR = 'CONFIG_SAVEERROR';
export const CONFIG_SAVED = 'CONFIG_SAVED';

export const CONFIG_LOADSTART = 'CONFIG_LOADSTART';
export const CONFIG_LOADERROR = 'CONFIG_LOADERROR';
export const CONFIG_LOADED = 'CONFIG_LOADED';

export const CONFIG_REMOVESTART = 'CONFIG_REMOVESTART';
export const CONFIG_REMOVEERROR = 'CONFIG_REMOVEERROR';
export const CONFIG_REMOVED = 'CONFIG_REMOVED';

export function ApiConfigSavePrepare(payload) {
  return {
    type: CONFIG_SAVEPREPARE,
    payload,
  };
}
export function ApiConfigSaveStart(payload) {
  return {
    type: CONFIG_SAVESTART,
    payload,
  };
}
export function ApiConfigLoadStart(payload) {
  return {
    type: CONFIG_LOADSTART,
    payload,
  };
}
export function ApiConfigRemoveStart(payload) {
  return {
    type: CONFIG_REMOVESTART,
    payload,
  };
}
export function ApiConfigSaveError(payload) {
  return {
    type: CONFIG_SAVEERROR,
    payload,
  };
}
export function ApiConfigLoadError(payload) {
  return {
    type: CONFIG_LOADERROR,
    payload,
  };
}
export function ApiConfigRemoveError(payload) {
  return {
    type: CONFIG_REMOVEERROR,
    payload,
  };
}
export function ApiConfigSaved(payload) {
  return {
    // payload should contain configId
    type: CONFIG_SAVED,
    payload,
  };
}
export function ApiConfigLoaded(payload) {
  return {
    type: CONFIG_LOADED,
    payload,
  };
}
export function ApiConfigRemoved(payload) {
  return {
    type: CONFIG_REMOVED,
    payload,
  };
}

export function ApiConfigSave(payload) {
  return function disp(dispatch) {
    // fetch starting
    dispatch(ApiConfigSaveStart(payload));

    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/configs';

    const configId = createGuid();

    const buff = Buffer.from(payload.configRaw);
    const encoded = buff.toString('base64');

    const myInit = {
      body: {
        configId,
        content: encoded,
        filename: payload.filename,
        datecreated: Date.now(),
        activefilter: payload.activeFilter,
        description: payload.description,
        customer: payload.customer,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };

    API.post(apiName, path, myInit)
      .then((response) => {
        event('User', 'ConfigSave', payload.auth.username.replace('@', '_'));
        // response should contain configId
        dispatch(ApiConfigSaved({ configId, response }));
      })
      .catch((error) => {
        console.error(error.response);
        event('Error', 'ConfigSave', error.response);
        dispatch(ApiConfigSaveError(error.response));
      });
  };
}
export function ApiConfigRemove(payload) {
  return function disp(dispatch) {
    dispatch(ApiConfigRemoveStart(payload));

    const { configId, auth } = payload;

    if (!validateGuid(configId)) {
      event('Error', 'ConfigRemove', 'Invalid Config ID');
      dispatch(ApiConfigRemoveError('Invalid Snippet'));
    }

    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/configs/${configId}`;
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };
    console.error('test API loading');

    API.del(apiName, path, myInit)
      .then((response) => {
        event('User', 'ConfigRemove', auth.username.replace('@', '_'));
        console.info(`API Response: ${JSON.stringify(response)}`);
        response.data.configId = configId;

        dispatch(ApiConfigRemoved(response.data));
        dispatch(ApiConfigsFetch(true));
      })
      .catch((error) => {
        event('Error', 'ConfigRemove', error);
        console.error(error.response);
        dispatch(ApiConfigRemoveError(error.response));
      });
  };
}

export function ApiConfigLoad(payload, parse, fileName) {
  return function disp(dispatch) {
    // fetch starting
    dispatch(ApiConfigLoadStart(payload));

    const { configId, auth } = payload;

    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/configs/${configId}`;
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    const t0 = performance.now();
    API.get(apiName, path, myInit)
      .then((response) => {
        // console.log("API Response: " + JSON.stringify(response));
        response.data.configId = configId;

        const buff = Buffer.from(response.data.content, 'base64');
        const decoded = buff.toString('ascii');

        const t1 = performance.now();
        console.info(`Loading took ${(t1 - t0).toFixed(0)} milliseconds.`);
        event('User', 'ConfigLoad', auth.username.replace('@', '_'));
        parse(fileName, decoded);
        dispatch(ApiConfigLoaded(response.data));
        dispatch(showAnalysis(true));
      })
      .catch((error) => {
        event('Error', 'ConfigLoad', error.response);
        console.error(error.response);
      });
  };
}
