import React, { Component } from 'react';
import DropFile from '../dropfile';

class Header extends Component {
  render() {
    return (
      <>
        <br />
        <DropFile />
      </>
    );
  }
}

export default Header;
