import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#31353E',
    padding: '20px',
    marginBottom: '20px',
  },
  image: {
    height: '100px',
    width: '100px',
    float: 'left',
    background: 'url(/img/analysis.png)',
    marginTop: '10px',
    marginRight: '10px',
  },
  content: {
    marginLeft: '100px',
  },
  heading: {
    fontSize: 20,
    color: '#fff',
  },
  text: {
    fontSize: 16,
    color: '#ccc',
  },
  link: {
    textDecoration: 'none',
    color: '#8AB0B7',
  },
}));

function RegisterBanner() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.image} />
      <div className={classes.content}>
        <Typography component="span" className={classes.heading}>
          Please{' '}
          <a className={classes.link} href="/#/login">
            register
          </a>{' '}
          to view all Config Analysis findings <br />
        </Typography>
        <Typography component="span" className={classes.text}>
          Registration is free and unlocks additional features:
          <ul>
            <li>All config analysis findings</li>
            <li>Export Analysis report</li>
            <li>Save and Share configuration snippets</li>
          </ul>
        </Typography>
      </div>
    </div>
  );
}

export default RegisterBanner;
