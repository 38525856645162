import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Button } from '@material-ui/core';

import CodeEditor from '../codeEditor';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#31353E',
    borderBottom: '1px solid #727272',
  },
  heading: {
    fontSize: 15,
  },
  headingDisabled: {
    fontsize: 14,
    fontStyle: 'italic',
    // color: '#555',
  },
  critical: {
    background: '#C92428',
    width: '32px',
    height: '32px',
    minWidth: 'auto',
    marginRight: '8px',
  },
  warning: {
    background: '#FFA497',
    width: '32px',
    height: '32px',
    minWidth: 'auto',
    marginRight: '8px',
  },
  medium: {
    background: '#FFD674',
    width: '32px',
    height: '32px',
    minWidth: 'auto',
    marginRight: '8px',
  },
  low: {
    background: '#40A3E0',
    width: '32px',
    height: '32px',
    minWidth: 'auto',
  },
  buttonCritical: {
    padding: '3px',
    margin: '0px',
    height: '25px',
    color: '#E03825',
    borderColor: '#E03825',
    marginRight: '20px',
    '&:hover': {
      borderColor: '#E03825',
    },
  },
  buttonWarning: {
    padding: '3px',
    margin: '0px',
    height: '25px',
    color: '#E57630',
    borderColor: '#E57630',
    marginRight: '20px',
    '&:hover': {
      borderColor: '#E57630',
    },
  },
  buttonMedium: {
    padding: '3px',
    margin: '0px',
    height: '25px',
    color: '#F1BE42',
    borderColor: '#F1BE42',
    marginRight: '20px',
    '&:hover': {
      borderColor: '#F1BE42',
    },
  },
  buttonLow: {
    padding: '3px',
    margin: '0px',
    height: '25px',
    color: '#89C0C8',
    borderColor: '#89C0C8',
    marginRight: '20px',
    '&:hover': {
      borderColor: '#89C0C8',
    },
  },
}));

function AnalysisItem(props) {
  const classes = useStyles();
  const { item } = props;

  // eslint-disable-next-line no-unused-vars
  // const [open, setOpen] = React.useState(false);

  const { correctivecommands } = item.rule;
  let itemobjects = item.objects;
  if (!itemobjects) itemobjects = [];
  const additionaloutput = correctivecommands || itemobjects.length > 0;
  let additionaltext = '';
  if (additionaloutput) {
    additionaltext = 'Applicable objects: ';
  }
  if (correctivecommands) {
    additionaltext = 'Corrective Commands: ';
  }
  let code = '';

  let severityIcon = '';
  if (item.rule.severity === 5) {
    severityIcon = (
      <Button
        variant="outlined"
        size="small"
        color="primary"
        className={classes.buttonLow}
      >
        Info
      </Button>
    );
  }
  if (item.rule.severity === 4) {
    severityIcon = (
      <Button
        variant="outlined"
        size="small"
        color="primary"
        className={classes.buttonLow}
      >
        Minor
      </Button>
    );
  }
  if (item.rule.severity === 3) {
    severityIcon = (
      <Button
        variant="outlined"
        size="small"
        color="primary"
        className={classes.buttonMedium}
      >
        Medium
      </Button>
    );
  }
  if (item.rule.severity === 2) {
    severityIcon = (
      <Button
        variant="outlined"
        size="small"
        color="primary"
        className={classes.buttonWarning}
      >
        Major
      </Button>
    );
  }
  if (item.rule.severity === 1) {
    severityIcon = (
      <Button
        variant="outlined"
        size="small"
        color="primary"
        className={classes.buttonCritical}
      >
        Critical
      </Button>
    );
  }

  if (additionaloutput) {
    // eslint-disable-next-line array-callback-return
    item.objects.map((io) => {
      if (correctivecommands) {
        let itemobjcommand = correctivecommands;
        const objproperties = Object.getOwnPropertyNames(io);
        for (let i = 0; i < objproperties.length; i += 1) {
          const propertyname = objproperties[i];
          itemobjcommand = itemobjcommand.replace(
            `{${propertyname}}`,
            io[propertyname],
          );
        }
        code = `${code + itemobjcommand}\n`;
      } else {
        code = `${code + io.baseline}\n`;
      }
    });
  }

  const deschtml = item.rule.description;

  return (
    <Accordion
      key={item.rule.name}
      className={classes.root}
      disabled={item.disabled}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {severityIcon}
        <Typography
          className={item.disabled ? classes.headingDisabled : classes.heading}
        >
          {item.disabled ? 'Registration needed' : item.rule.name}
        </Typography>
      </AccordionSummary>
      {item.disabled ? null : (
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="span" className={classes.secondaryHeading}>
                <p
                  className="analysisText"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: deschtml }}
                />
              </Typography>
            </Grid>

            {additionaloutput && (
              <Grid item xs={12}>
                <div className="fullwidth">
                  <Typography>{additionaltext}</Typography>

                  <CodeEditor code={code} uid={item.rule.name} height="100%" />
                </div>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      )}
    </Accordion>
  );
}

export default AnalysisItem;
