import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateCustomer,
  updateCustomerContact,
} from '../../../actions/healthcheck/inputForm';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { updateCustomer, updateCustomerContact },
    dispatch,
  );
}

const mapStateToProps = (state) => ({
  form: state.healthcheck.form,
});

class InputForm extends Component {
  constructor(props) {
    super(props);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleCustomerContactChange =
      this.handleCustomerContactChange.bind(this);
  }

  handleCustomerChange(event) {
    const { updateCustomer: updateCustomerFn } = this.props;
    updateCustomerFn(event.target.value);
  }

  handleCustomerContactChange(event) {
    const { updateCustomerContact: updateCustomerContactFn } = this.props;
    updateCustomerContactFn(event.target.value);
  }

  render() {
    const { form } = this.props;
    return (
      <div
        className="inputform"
        style={{ textAlign: 'center', display: 'inline-block' }}
      >
        <table>
          <tr>
            <td>Customer Name: </td>
            <td>
              <input
                type="text"
                id="customer"
                value={form.customername}
                onChange={this.handleCustomerChange}
                style={{
                  width: '300px',
                  fontSize: '1.1rem',
                  backgroundColor: '#535353',
                  color: '#fff',
                  border: '1px solid #ccc',
                  margin: '10px',
                  padding: '5px',
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Customer Contact: </td>
            <td>
              <input
                type="text"
                id="customercontact"
                value={form.customercontact}
                onChange={this.handleCustomerContactChange}
                style={{
                  width: '300px',
                  fontSize: '1.1rem',
                  backgroundColor: '#535353',
                  color: '#fff',
                  border: '1px solid #ccc',
                  margin: '10px',
                  padding: '5px',
                }}
              />
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputForm);
