import { FETCH_CONF_START } from '../../actions/fetchConf';
import {
  SHAREDSNIPPET_SAVESTART,
  SHAREDSNIPPET_LOADSTART,
  SHAREDSNIPPET_LOADED,
  SHAREDSNIPPET_SAVED,
  SHAREDSNIPPET_REMOVEERROR,
  SHAREDSNIPPET_LOADERROR,
} from '../../actions/ApiSharedSnippet';
import {
  CONFIG_LOADSTART,
  CONFIG_LOADED,
  CONFIG_SAVED,
  CONFIG_SAVESTART,
} from '../../actions/ApiConfig';
import { PARSER_START, PARSER_DONE, PARSER_ERROR } from '../../actions/parser';
import {
  CONFIGS_RETRIEVING,
  CONFIGS_RETRIEVED,
} from '../../actions/ApiConfigs';

const initialState = {
  isConfigActivity: false,
  currentActivity: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONF_START: {
      return {
        ...state,
        isConfigActivity: true,
        currentActivity: 'Loading...',
      };
    }

    case SHAREDSNIPPET_SAVESTART: {
      return { ...state, currentActivity: 'Saving...', isConfigActivity: true };
    }
    case SHAREDSNIPPET_LOADSTART: {
      return {
        ...state,
        currentActivity: 'Loading...',
        isConfigActivity: true,
      };
    }
    case SHAREDSNIPPET_LOADERROR: {
      return {
        ...state,
        currentActivity: 'Error',
        isConfigActivity: false,
      };
    }
    case SHAREDSNIPPET_REMOVEERROR: {
      return {
        ...state,
        currentActivity: 'Error',
        isConfigActivity: false,
      };
    }

    case SHAREDSNIPPET_SAVED: {
      return { ...state, currentActivity: 'Done...', isConfigActivity: false };
    }

    case SHAREDSNIPPET_LOADED: {
      return {
        ...state,
        currentActivity: 'Parsing...',
        isConfigActivity: true,
      };
    }

    case CONFIGS_RETRIEVING: {
      return {
        ...state,
        currentActivity: 'Retrieving...',
        isConfigActivity: true,
      };
    }

    case CONFIGS_RETRIEVED: {
      return { ...state, currentActivity: 'Done...', isConfigActivity: false };
    }
    case PARSER_START: {
      return {
        ...state,
        isConfigActivity: true,
        currentActivity: 'Parsing...',
      };
    }
    case PARSER_DONE: {
      return { ...state, isConfigActivity: false, currentActivity: 'Done...' };
    }
    case PARSER_ERROR: {
      return { ...state, isConfigActivity: false, currentActivity: 'Error...' };
    }

    case CONFIG_SAVED:
      // config saved by user, set activeconfigid
      return { ...state, isConfigActivity: false, currentActivity: 'Done...' };
    case CONFIG_LOADED:
      // config loaded by user, set activeconfigid
      return { ...state };
    case CONFIG_SAVESTART:
      return { ...state, isConfigActivity: true, currentActivity: 'Saving...' };

    case CONFIG_LOADSTART:
      return {
        ...state,
        isConfigActivity: true,
        currentActivity: 'Loading...',
      };

    default:
      return state;
  }
};
