/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Container from '@material-ui/core/Container';
import ArrowLeftOutlinedIcon from '@material-ui/icons/ArrowLeftOutlined';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Paper from '@material-ui/core/Paper';
import ToolBar from '../../components/toolbar';
import { withParser } from '../../ParserContext';

const styles = () => ({
  root: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  backdrop: {
    zIndex: 11,
    color: '#fff',
  },
  paper: {
    padding: 15,
    textAlign: 'left',
    background: '#22252a',
    opacity: '0.90',
    borderRadius: 4,
    margin: '5px auto',
    marginTop: '5px',
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
});

const CloseButton = withStyles(() => ({
  root: {
    color: '#fff',
    backgroundColor: '#444',
    '&:hover': {
      backgroundColor: '#666',
    },
    top: '4px',
  },
}))(Button);

class WhatsnewPage extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }

  state = {
    md: '',
  };

  componentDidMount() {
    fetch('./changelog.md')
      .then((resp) => resp.text())
      .then((txt) => this.setState({ md: txt }));
  }

  goBack() {
    const { history } = this.props;
    history.goBack();
  }

  // eslint-disable-next-line class-methods-use-this

  render() {
    const { classes } = this.props;
    const { md } = this.state;
    return (
      <Container maxWidth className={classes.root}>
        <ToolBar />
        <Paper className={classes.paper}>
          <Box minHeight="400px">
            <Container>
              <CloseButton
                aria-label="Close"
                color="primary"
                onClick={this.goBack}
              >
                <ArrowLeftOutlinedIcon />
                Back
              </CloseButton>
              <ReactMarkdown
                className="changelog"
                children={md}
                remarkPlugins={[remarkGfm]}
              />
            </Container>
          </Box>
        </Paper>
      </Container>
    );
  }
}

WhatsnewPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withParser(WhatsnewPage)));
