/* eslint-disable class-methods-use-this */
/* eslint-disable no-shadow */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import { arrayOf, shape, string, func, oneOfType, object } from 'prop-types';
import classNames from 'classnames';
import { prop } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

const pickClassName = prop('className');
/** Prop-type for a recursive data structure */
const tree = {
  value: string.isRequired,
};

Object.assign(tree, {
  nodes: arrayOf(oneOfType([shape(tree), string])),
});

const styles = () => ({
  panel: {
    width: '100%',
    paddingRight: 0,
    paddingLeft: 0,
    position: 'unset',

    backgroundColor: 'unset',
  },
  listitem: {
    marginLeft: 8,
  },
  panelSummary: {
    padding: 0,
    marginLeft: 8,
  },
  panelDetails: {
    padding: 0,
    display: 'block',
  },
  panelExpanded: {
    margin: 0,
    '&:before': {
      opacity: 0,
    },
    position: 'unset',
  },
  childPanel: {
    '&:before': {
      opacity: 0,
    },
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'noWrap',
    maxWidth: '75vw',
  },
  expandIcon: {},
});

/**
 * Render a tree view.
 */
class MyTreeView extends Component {
  static propTypes = {
    /** The data to render as a tree view */
    tree: arrayOf(shape(tree)).isRequired,
    /** Callback function fired when a tree leaf is clicked. */
    onLeafClick: func,
    /** A search term to refine the tree */
    searchTerm: string,
    /** Properties applied to the ExpansionPanelSummary element. */
    expansionPanelSummaryProps: object,
    /** Properties applied to the ExpansionPanelDetails element. */
    expansionPanelDetailsProps: object,
    /** Properties applied to the ListItem element. */
    listItemProps: object,
  };

  static defaultProps = {
    searchTerm: null,
    onLeafClick: null,
    expansionPanelSummaryProps: null,
    expansionPanelDetailsProps: null,
    listItemProps: null,
  };

  state = {
    expanded: null,
  };

  // createFilteredTree = memoizeWith(
  //   (tree, searchTerm) => `${tree.toString()}-${searchTerm}`,
  //   (tree, searchTerm) => (searchTerm ? this.filter(tree) : tree),
  // );

  constructor(props) {
    super(props);
    this.handleLeafClick = this.handleLeafClick.bind(this);
  }

  handleLeafClick = (value, parent) => {
    const { onLeafClick: onLeafClickAlt } = this.props;
    if (value) {
      if (onLeafClickAlt) {
        onLeafClickAlt(value, parent);
      }
    } else if (onLeafClickAlt) {
      onLeafClickAlt('clear', parent);
    }
  };

  getNodeValue(node) {
    return typeof node === 'string' ? node : node.value;
  }

  getNodeObjecttype(node) {
    return node.objecttype;
  }

  renderNode = (node, parent, depth = 0) => {
    const {
      theme: {
        // eslint-disable-next-line no-unused-vars
        spacing: { unit2 },
      },
      classes,
      searchTerm,
      expansionPanelSummaryProps,
      expansionPanelDetailsProps,
      listItemProps,
      ...props
    } = this.props;
    const unit = 14;
    const { expanded } = this.state;

    const value = this.getNodeValue(node);
    const objecttype = this.getNodeObjecttype(node);

    const isLeaf = this.isLeaf(node);
    const isUsed = this.isUsed(node);
    let textIndent = isLeaf
      ? depth * unit + unit + (parent ? unit : 0)
      : depth * unit + unit;
    textIndent = depth * unit + unit;

    if (isLeaf && searchTerm && !value.includes(searchTerm)) {
      return null;
    }
    if (isLeaf) {
      textIndent += 8;
    }

    const itemIcon = null;

    if (isLeaf) {
      if (objecttype == null) {
        // leaf without obecttype = one of the default menu items that is not in the config
        return null;
      }
      return (
        <ListItem
          disableGutters
          style={{ textIndent }}
          key={value}
          id={value}
          value={value}
          onClick={() => this.handleLeafClick(objecttype, parent)}
          button
          {...listItemProps}
        >
          {itemIcon}
          <div className={classes.text}>{value}</div>
        </ListItem>
      );
    }

    const expansionPanelClasses = {
      expanded: classes.panelExpanded,
      ...(parent ? { root: classes.childPanel } : null),
    };

    if (depth === 0) {
      if (isUsed) {
        return (
          <Accordion
            classes={expansionPanelClasses}
            style={{ textIndent }}
            key={node.value}
            expanded={expanded === node.value}
            onChange={this.handleChange(node.value)}
            elevation={0}
            {...props}
            className={classNames(classes.panel, pickClassName(props))}
          >
            <AccordionSummary
              classes={{
                expandIcon: classes.expandIcon,
                root: classes.panelSummary,
              }}
              onClick={() => this.handleLeafClick(objecttype, parent)}
              {...expansionPanelSummaryProps}
              className={classNames(pickClassName(expansionPanelSummaryProps))}
              expandIcon={<KeyboardArrowDown />}
            >
              {itemIcon}
              <div className={classes.text}>{node.value}</div>
            </AccordionSummary>
            <AccordionDetails
              {...expansionPanelDetailsProps}
              classes={{ root: classes.panelDetails }}
              className={classNames(pickClassName(expansionPanelDetailsProps))}
            >
              {node.nodes.map((l) => this.renderNode(l, node, depth + 1))}
            </AccordionDetails>
          </Accordion>
        );
      }
      return (
        <Accordion
          classes={expansionPanelClasses}
          style={{ textIndent }}
          key={node.value}
          expanded={expanded === node.value}
          onChange={this.handleChange(node.value)}
          elevation={0}
          disabled
          {...props}
          className={classNames(classes.panel, pickClassName(props))}
        >
          <AccordionSummary
            classes={{
              expandIcon: classes.expandIcon,
              root: classes.panelSummary,
            }}
            onClick={() => this.handleLeafClick(objecttype, parent)}
            {...expansionPanelSummaryProps}
            className={classNames(pickClassName(expansionPanelSummaryProps))}
            expandIcon={<KeyboardArrowDown />}
          >
            {itemIcon}
            <div className={classes.text}>{node.value}</div>
          </AccordionSummary>
          <AccordionDetails
            {...expansionPanelDetailsProps}
            classes={{ root: classes.panelDetails }}
            className={classNames(pickClassName(expansionPanelDetailsProps))}
          >
            {node.nodes.map((l) => this.renderNode(l, node, depth + 1))}
          </AccordionDetails>
        </Accordion>
      );
    }
    return (
      <Accordion
        classes={expansionPanelClasses}
        style={{ textIndent }}
        key={node.value}
        elevation={0}
        {...props}
        className={classNames(classes.panel, pickClassName(props))}
      >
        <AccordionSummary
          classes={{
            expandIcon: classes.expandIcon,
            root: classes.panelSummary,
          }}
          onClick={() => this.handleLeafClick(objecttype, parent)}
          {...expansionPanelSummaryProps}
          className={classNames(pickClassName(expansionPanelSummaryProps))}
          expandIcon={<KeyboardArrowDown />}
        >
          {itemIcon}
          <div className={classes.text}>{node.value}</div>
        </AccordionSummary>
        <AccordionDetails
          {...expansionPanelDetailsProps}
          classes={{ root: classes.panelDetails }}
          className={classNames(pickClassName(expansionPanelDetailsProps))}
        >
          {node.nodes.map((l) => this.renderNode(l, node, depth + 1))}
        </AccordionDetails>
      </Accordion>
    );
  };

  handleChange = (panel) => (event, expanded) => {
    if (
      panel === 'System' ||
      panel === 'AppExpert' ||
      panel === 'Traffic Management' ||
      panel === 'Optimization' ||
      panel === 'Security' ||
      panel === 'NetScaler Gateway' ||
      panel === '_Unmatched'
    ) {
      this.setState({
        expanded: expanded ? panel : false,
      });
    }
  };

  isLeaf(node) {
    return typeof node === 'string' || !node.nodes || !node.nodes.length;
  }

  isUsed(node) {
    //
    let used = false;
    if (node.nodes) {
      if (node.nodes.length > 0) {
        for (let i = 0; i < node.nodes.length; i += 1) {
          used = this.isUsed(node.nodes[i]);
          if (used) {
            return true;
          }
        }
      } else if (node.objecttype) {
        if (node.objecttype != null) {
          return true;
        }
      }
    } else if (node.objecttype) {
      if (node.objecttype != null) {
        return true;
      }
    }
    return false;
  }

  filter(tree) {
    const { searchTerm } = this.props;

    return tree.filter((node) => {
      const value = this.getNodeValue(node);
      const isLeaf = this.isLeaf(node);

      if (value.includes(searchTerm)) {
        return true;
      }

      if (isLeaf) {
        return false;
      }

      const subtree = this.filter(node.nodes);

      return Boolean(subtree.length);
    });
  }

  render() {
    const { tree } = this.props;
    return tree.map((node) => this.renderNode(node, null));
  }
}

export default withStyles(styles, { withTheme: true })(MyTreeView);
