/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* jshint esversion: 9 */
import React, { Component } from 'react';
import ArrowLeftOutlinedIcon from '@material-ui/icons/ArrowLeftOutlined';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import { GuiToolbarChange } from '../../actions/gui';

const styles = {
  root: {
    flexGrow: 1,
  },
  buttonText: {
    textDecoration: 'none',
    textTransform: 'none',
    marginLeft: '10px',
  },
};

const mapStateToProps = (state) => ({
  configRaw: state.config.configRaw,
  configFiltered: state.config.configFiltered,
  activeShareSnippetUrl: state.config.activeShareSnippetUrl,
  APIConfigs: state.apiconfigs,
  GUI: state.gui,
  auth: state.auth,
  status: state.status,
  analysisItems: state.config.analysisItems,
  stats: state.config.stats,
  fileName: state.config.fileName,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      GuiToolbarChange,
    },
    dispatch,
  );
}

const style = {
  background: '#464C54',
  paddingLeft: '12px',
};

const buttonStyle = {
  minWidth: 30,
  padding: '8px 8px',
};

class HealthcheckToolBar extends Component {
  constructor(props) {
    super(props);

    this.backBtn = this.backBtn.bind(this);
  }

  backBtn = (e) => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Toolbar style={style} variant="dense">
          <a>
            <Tooltip title="Back">
              <Button style={buttonStyle} onClick={this.backBtn}>
                <ArrowLeftOutlinedIcon />
                <Typography className={classes.buttonText}>Back</Typography>
              </Button>
            </Tooltip>
          </a>
        </Toolbar>
      </div>
    );
  }
}
HealthcheckToolBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  // classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(HealthcheckToolBar)),
);
