import {
  SETTINGS_RETRIEVING,
  SETTINGS_RETRIEVED,
} from '../../actions/ApiSettings';

const initialState = {
  isSettingsRetrieving: false,
  settings: [],

  isError: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_RETRIEVED:
      // list of settings for current user is fetched
      return {
        ...state,
        isSettingsRetrieving: false,
        settings: action.payload,
      };
    case SETTINGS_RETRIEVING:
      // beginning of fetch configs list
      return {
        ...state,
        isSettingsRetrieving: true,
      };

    default:
      return state;
  }
};
