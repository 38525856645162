export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMERCONTACT = 'UPDATE_CUSTOMERCONTACT';
export function updateCustomer(payload) {
  return {
    type: UPDATE_CUSTOMER,
    payload,
  };
}

export function updateCustomerContact(payload) {
  return {
    type: UPDATE_CUSTOMERCONTACT,
    payload,
  };
}
