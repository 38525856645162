/* eslint-disable react/jsx-curly-brace-presence */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { checkLogin } from '../../actions/checkLogin';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ checkLogin }, dispatch);
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  gui: state.gui,
});

const styles = () => ({
  root: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  paper: {
    padding: 15,
    textAlign: 'center',
    background: '#22252a',
    opacity: '0.90',
    borderRadius: 4,
    margin: '5px auto',
    marginTop: '20px',
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  forminput: {
    // color: 'black',
    minWidth: '300px',
    width: 200,
  },
  arrowfont: {
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  leftalign: {
    textAlign: 'left',
  },
});

class PasswordResetConfirmPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pass1: '',
      pass2: '',
      otp: '',
      alert: {
        msg: 'Enter the verification code that was sent and the new password',
        status: '',
      },
    };
  }

  pass1Change = (e) => {
    this.setState({ pass1: e.target.value });
  };

  pass2Change = (e) => {
    this.setState({ pass2: e.target.value });
  };

  otpChange = (e) => {
    this.setState({ otp: e.target.value });
  };

  Cancel = () => {
    window.location.href = process.env.REACT_APP_COMMON_URL;
  };

  confirmPassword = (event) => {
    const { pass1, pass2, otp } = this.state;
    const { history, gui } = this.props;
    event.preventDefault();

    if (!otp) {
      this.setState({
        alert: { msg: 'Verification code cannot be empty.', status: 'alert' },
      });
    } else if (!pass1) {
      this.setState({
        alert: { msg: 'Password cannot be empty.', status: 'alert' },
      });
    } else if (!pass2) {
      this.setState({
        alert: { msg: 'Password cannot be empty.', status: 'alert' },
      });
    } else if (pass1 !== pass2) {
      this.setState({
        alert: { msg: 'Passwords do not match', status: 'alert' },
      });
    } else {
      Auth.forgotPasswordSubmit(gui.passwordresetUsername, otp, pass1)
        .then(() => {
          document.getElementById('form-inputs').innerHTML = '';
          history.push('/login');
        })
        .catch((err) =>
          this.setState({ alert: { msg: err.message, status: 'alert' } }),
        );
    }
  };

  render() {
    const { classes, gui } = this.props;
    const { alert } = this.state;
    return (
      <Container className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.arrowfont} gutterBottom>
            Confirm password reset
          </Typography>

          <p className={alert.status}>{alert.msg}</p>
          <form id="form-inputs" onSubmit={this.confirmPassword}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              style={{ marginTop: '40px', minWidth: '200' }}
            >
              <Grid item xs={12}>
                <TextField
                  disabled
                  className={classes.forminput}
                  label="E-mail"
                  fullWidth
                  value={gui.passwordresetUsername}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  className={classes.forminput}
                  label="OTP Code"
                  fullWidth
                  onChange={this.otpChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  type="password"
                  className={classes.forminput}
                  label="New Password"
                  fullWidth
                  onChange={this.pass1Change}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  type="password"
                  className={classes.forminput}
                  label="New Password (Confirm)"
                  fullWidth
                  onChange={this.pass2Change}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" onClick={this.Cancel}>
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button type="submit" variant="contained" color="primary">
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    ); // return end
  } // render end
} // class LoginPage end
PasswordResetConfirmPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PasswordResetConfirmPage),
  ),
);
