import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';

class FilterItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editmode: false,
      editvalue: '',
    };
    this.onEditFilter = this.onEditFilter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editFilterDone = this.editFilterDone.bind(this);
    this.onRemoveFilter = this.onRemoveFilter.bind(this);
  }

  handleChange(event) {
    this.setState({ editvalue: event.target.value });
  }

  onRemoveFilter(e) {
    const { onFilterRemoved: onFilterRemovedAlt, filterindex } = this.props;
    e.preventDefault();
    // this.props.FetchConfStart(true);
    // this.setState({editvalue: this.props.filter.filtertype + ":" + this.props.filter.filtervalue})
    // this.setState({editmode: true});
    onFilterRemovedAlt({ index: filterindex });
  }

  onEditFilter(e) {
    const { filter } = this.props;
    e.preventDefault();
    // this.props.FetchConfStart(true);
    this.setState({
      editvalue: `${filter.filtertype}:${filter.filtervalue}`,
    });
    this.setState({ editmode: true });
  }

  editFilterDone(e) {
    const { editvalue } = this.state;
    const { onFilterUpdated: onFilterUpdatedAlt, filterindex } = this.props;
    e.preventDefault();
    //
    // var newvalue = e.target.value;
    const newvalue = editvalue;
    const f = {};

    if (newvalue.indexOf(':') > 0) {
      f.filtertype = newvalue.split(':')[0];
      f.filtervalue = newvalue.split(':')[1];
    } else {
      f.filtertype = 'text';
      f.filtervalue = newvalue;
    }

    this.setState({ editmode: false });
    onFilterUpdatedAlt({ index: filterindex, filter: f });
  }

  render() {
    const { editmode, editvalue } = this.state;
    const { filter } = this.props;
    const toolbarInputStyle = {
      // backgroundColor: '#fff',
      color: '#000',
      display: 'inline',
      padding: 0,
      border: '0px',
      borderRadius: '3px',
      borderBottom: '0px',
      margin: '0px',
      paddingLeft: '5px',
      transition: 'none',
      height: '30px',
      lineHeight: '30px',
      width: 'auto',
      float: 'left',
      minWidth: 180,
    };

    if (editmode) {
      return (
        <span className="filter-tag-edit">
          <input
            style={toolbarInputStyle}
            className="filter-tag-input"
            type="text"
            value={editvalue}
            onChange={this.handleChange}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                this.editFilterDone(ev);
              }
            }}
            // eslint-disable-next-line react/jsx-no-comment-textnodes
          />
          <a
            className="i"
            href="#"
            onClick={this.editFilterDone}
            aria-label="done"
          >
            <DoneIcon color="primary" />
          </a>
        </span>
      );
    }
    let colorclass = 'filter-tag';
    if (filter.filtertype === 'type') {
      colorclass = 'filter-tag-blue';
    }
    return (
      <span className={colorclass}>
        <a className="filtertype">{filter.filtertype}</a>:&nbsp;
        <a className="filtervalue">{filter.filtervalue}</a>
        <a className="i" href="#" onClick={this.onEditFilter} aria-label="edit">
          <EditIcon color="action" style={{ fontSize: 16 }} />
        </a>
        <a
          style={{ align: 'right' }}
          className="i"
          href="#"
          aria-label="close"
          onClick={this.onRemoveFilter}
        >
          <CloseIcon color="action" style={{ fontSize: 16 }} />
        </a>
      </span>
    );
  }
}

export default FilterItem;
