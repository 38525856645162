import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router';

const mapStateToProps = (state) => ({
  auth: state.auth,
  gui: state.gui,
});

const styles = () => ({
  root: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  paper: {
    padding: 15,
    textAlign: 'center',
    background: '#22252a',
    opacity: '0.90',
    borderRadius: 4,
    margin: '5px auto',
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  forminput: {
    // color: 'black',
  },
  arrowfont: {
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  leftalign: {
    textAlign: 'left',
  },
  tableicon: {
    fontSize: '1.1rem',
  },
});

class VerifPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      alert: {
        msg: 'Please enter the confirmation code received by mail',
        status: '',
      },
    };
  }

  codeChange = (e) => {
    this.setState({ code: e.target.value });
  };

  confirmSignUp = (event) => {
    const { code } = this.state;
    const { history, gui } = this.props;
    const cleanedcode = code.trim();

    event.preventDefault();
    Auth.confirmSignUp(gui.registerUsername, cleanedcode)
      .then(() => {
        this.setState({
          alert: { msg: 'Your account is validated. You can now login.' },
        });
        history.push('/register/done');
      })
      .catch((err) => {
        this.setState({ alert: { msg: err.message, status: 'alert' } });
        if (
          err.message ===
          'User cannot confirm because user status is not UNCONFIRMED.'
        ) {
          this.setState({
            alert: {
              msg: 'Your account is not waiting for confirmation. Are you trying to validate your account twice ?',
            },
          });
        }
      });
  };

  render() {
    const { classes, gui } = this.props;
    const { alert } = this.state;
    return (
      <Container className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.arrowfont} gutterBottom>
            Validate your account
          </Typography>

          <p className={alert.status}>{alert.msg} </p>

          <form onSubmit={this.confirmSignUp}>
            <Grid container spacing={3} style={{ marginTop: '40px' }}>
              <Grid item xs={12}>
                <TextField
                  disabled
                  className={classes.forminput}
                  label="Email Address"
                  fullWidth
                  value={gui.registerUsername}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  className={classes.forminput}
                  label="Your verification code"
                  fullWidth
                  onChange={this.codeChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" waves="light">
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    ); // return end
  } // render end
} // class end
VerifPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, null)(VerifPage)),
);
