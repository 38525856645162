import { AUTH_LOGIN } from '../../actions/authLogin';
import { AUTH_LOGOUT } from '../../actions/authLogout';
import { CHECK_LOGIN } from '../../actions/checkLogin';
import { AUTH_UPDATEATTR } from '../../actions/updateAttr';

const initialState = {
  isAuthenticated: false,
  isAuthenticating: true,
  session: {},
  username: null,
  firstname: null,
  attributes: null,
  jwt: null,
  td: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN: {
      break;
    }
    case CHECK_LOGIN: {
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        username: action.payload.idToken.payload.email,
        firstname: action.payload.idToken.payload.given_name,
        jwt: action.payload.accessToken.jwtToken,
        session: action.payload,
        attributes: action.payload.attributes,
        td: action.payload.idToken.payload.td === 'true',
      };
    }
    case AUTH_UPDATEATTR: {
      // action.payload is new set of attributes but without e.g. email
      // merge with existing attributes
      const newattrib = { ...state.attributes, ...action.payload };

      return { ...state, attributes: newattrib };
    }

    case AUTH_LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        attributes: null,
        session: {},
        username: null,
        firstname: null,
        jwt: null,
      };
    }
    default:
      return state;
  }
  return state;
};
