/* eslint-disable no-console */
export const ADD_TEMPLATE = 'ADD_TEMPLATE';

export function addTemplate(payload) {
  console.log('action: addTemplate');
  return {
    type: ADD_TEMPLATE,
    payload,
  };
}
