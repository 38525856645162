export const GUI_TOOLBAR_CHANGE = 'GUI_TOOLBAR_CHANGE';
export const GUI_TOOLBAR_ADDFILTER_PREPARE = 'GUI_TOOLBAR_ADDFILTER_PREPARE';
export const GUI_TOOLBAR_ADDFILTER_DONE = 'GUI_TOOLBAR_ADDFILTER_DONE';
export const GUI_SET_PASSWORDRESETUSERNAME = 'GUI_SET_PASSWORDRESETUSERNAME';
export const GUI_SET_REGISTERUSERNAME = 'GUI_SET_REGISTERUSERNAME';
export const UPDATE_PATH = 'UPDATE_PATH';

export function GuiToolbarChange(payload) {
  return {
    type: GUI_TOOLBAR_CHANGE,
    payload,
  };
}

export function GuiToolbarAddFilterPrepare(payload) {
  return {
    type: GUI_TOOLBAR_ADDFILTER_PREPARE,
    payload,
  };
}
export function GuiToolbarAddFilterDone(payload) {
  return {
    type: GUI_TOOLBAR_ADDFILTER_DONE,
    payload,
  };
}

export function UpdatePath(payload) {
  return {
    type: UPDATE_PATH,
    payload,
  };
}

export function UpdatePasswordResetUsername(payload) {
  return {
    type: GUI_SET_PASSWORDRESETUSERNAME,
    payload,
  };
}

export function UpdateRegisterUsername(payload) {
  return {
    type: GUI_SET_REGISTERUSERNAME,
    payload,
  };
}
