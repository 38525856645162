/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import Paper from '@material-ui/core/Paper';
import HealthcheckToolBar from '../../components/healthcheckToolbar';

import Upload from '../../components/healthcheck/upload';
import FileList from '../../components/healthcheck/filelist';
import TemplateSelect from '../../components/healthcheck/templateselect';
import Buttons from '../../components/healthcheck/buttons';
import InputForm from '../../components/healthcheck/inputform';

import { withParser } from '../../ParserContext';

const styles = (theme) => ({
  root: {
    flex: 1,
    padding: '0px',
  },
  backdrop: {
    zIndex: 11,
    color: '#fff',
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#fff',
    textAlign: 'left',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  inputFields: {
    backgroundColor: '#31353E',
    border: '2px solid #464C54',
    padding: '6px',
    margin: '10px',
    color: '#ccc',
    textAlign: 'center',
  },
  paper: {
    padding: 15,
    textAlign: 'left',
    background: '#22252a',
    opacity: '0.90',
    borderRadius: 4,
    margin: '5px auto',
    marginTop: '5px',
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const mapStateToProps = (state) => ({
  inputs: state.healthcheck.inputs,
  template: state.healthcheck.template,
});

class HealthCheckPage extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    const { history } = this.props;
    history.goBack();
  }

  // eslint-disable-next-line class-methods-use-this

  render() {
    const { classes, template, inputs } = this.props;
    return (
      <Container maxWidth className={classes.root}>
        <HealthcheckToolBar />
        <Paper className={classes.paper}>
          <Box minHeight="400px">
            <Container>
              <div>
                <div className={classes.inputFields}>
                  <div className={classes.heading}>
                    Step 1: Provide healthcheck report details
                  </div>
                  <InputForm />
                </div>
                <div className={classes.inputFields}>
                  <div className={classes.heading}>
                    Step 2: Provide Template .docx to use
                  </div>
                  <TemplateSelect template={template} />
                </div>
                <div className={classes.inputFields}>
                  <div className={classes.heading}>
                    Step 3: Provide JSON export file for each instance
                  </div>
                  <FileList inputs={inputs} />
                  <Upload />
                </div>
                <div className={classes.inputFields}>
                  <Buttons />
                </div>
              </div>
            </Container>
          </Box>
        </Paper>
      </Container>
    );
  }
}

HealthCheckPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withParser(HealthCheckPage)),
  ),
);
