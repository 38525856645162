/* eslint-disable react/style-prop-object */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CheckIcon from '@material-ui/icons/Check';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withRouter } from 'react-router';
import DropFile from '../../components/dropfile';

const styles = () => ({
  root: {
    flex: 1,
    maxWidth: '1600px',
    textAlign: 'center',
    paddingTop: '30px',
  },
  paper: {
    padding: 15,
    textAlign: 'center',
    background: 'none',
    borderRadius: 4,
    margin: '5px auto',
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  forminput: {
    // color: 'black',
  },
  arrowfont: {
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  leftalign: {
    textAlign: 'left',
  },
  tableicon: {
    fontSize: '1.1rem',
  },
  homeColumn: {
    border: '2px solid #494F57',
    backgroundColor: '#212529',
    fontSize: 14,
    minHeight: 610,
    verticalAlign: 'top',
    flexDirection: 'column',
    opacity: 0.9,
  },
  columnHeader: {
    paddingTop: '10px',
    fontSize: 30,
    color: '#fff',
    textAlign: 'center',
  },
  checkIcon: {
    color: '#94D451',
    marginRight: '10px',
  },
  columnRow: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  homeButton: {
    border: '1px solid #91CDD4',
    maxWidth: '300px',
    minWidth: '250px',
    height: '70px',
    color: '#fff',
    textDecoration: 'none',
    textTransform: 'none',
    fontSize: 20,
    marginBottom: '20px',
    bottom: '20px',
    position: 'relative',
  },
  buttonLink: {
    textDecoration: 'none',
  },
  flexitem: {
    flexGrow: 1,
  },
});

class HomePage extends Component {
  onUpload = () => {
    const { history } = this.props;
    history.push('/snippet');
  };

  render() {
    const { classes } = this.props;
    return (
      // <div className="homepage">
      <Container className={classes.root}>
        <Typography variant="h3">
          Arrow&apos;s NetScaler config analyser
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10}>
            <p>Welcome to Arrow&apos;s NetScaler config analyser</p>
            <p>
              This tool is secure and anonymous. It can analyse, share and
              export configuration files to your local browser. Additional
              features are available for registered users.
            </p>
            <Button
              waves="light"
              className="registerButton toupper"
              node="a"
              href="./#/register"
            >
              Free registration
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={7}
          className={classes.columnRow}
        >
          <Grid item md={4}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.homeColumn}
            >
              <Grid item>
                <Typography className={classes.columnHeader}>
                  Online Config Analysis
                </Typography>
              </Grid>
              <Grid item className={classes.flexitem}>
                <table className="home-table">
                  <thead>
                    <tr>
                      <td>Anonymous</td>
                      <td>Free Registration</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>View max 2 issues</td>
                      <td>See all analysis issues</td>
                    </tr>
                    <tr>
                      <td>Configuration filtering</td>
                      <td>Config analysis report</td>
                    </tr>
                    <tr>
                      <td aria-label="save" />
                      <td>Save, load and share snippets</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item>
                <Card className="privacy-card">
                  <CardContent className="privacy-content">
                    <Typography variant="h6" className="privacy-title">
                      Your config is safe!
                    </Typography>
                    <Typography variant="body2" component="p">
                      Your configuration is only loaded in your local browser
                      session unless you explicitly save or share your config
                    </Typography>
                  </CardContent>
                  {/* <CardActions>
                    <Button size="small" className="privacy-button">Read More</Button>
                  </CardActions> */}
                </Card>
              </Grid>
              <Grid item>
                <DropFile onUpload={this.onUpload} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.homeColumn}
            >
              <Grid item>
                <Typography className={classes.columnHeader}>
                  Full health check
                </Typography>
              </Grid>
              <Grid item className={classes.flexitem}>
                <table className="home-table">
                  <thead>
                    <tr>
                      <td>Features</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <CheckIcon className={classes.checkIcon} />
                        In-depth NetScaler Health check
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CheckIcon className={classes.checkIcon} />
                        Extensive health check report
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CheckIcon className={classes.checkIcon} />
                        Best practice remediation and implementation plan
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {' '}
                        <CheckIcon className={classes.checkIcon} />
                        NetScaler hosting and topology Audit
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item>
                <a
                  href="https://www.arrow.com/globalecs/uk/services/managed-services/netscaler/"
                  className={classes.buttonLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className={classes.homeButton}>
                    More Information
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.homeColumn}
            >
              <Grid item>
                <Typography className={classes.columnHeader}>
                  Managed Service for NetScaler
                </Typography>
              </Grid>
              <Grid item className={classes.flexitem}>
                <table className="home-table">
                  <thead>
                    <tr>
                      <td>Features</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <CheckIcon className={classes.checkIcon} />
                        Continuous best practice checks and implementation
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CheckIcon className={classes.checkIcon} />
                        Base - Gold - Platinum - 24/7 service levels
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CheckIcon className={classes.checkIcon} />
                        Pro-active performance and availability monitoring
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CheckIcon className={classes.checkIcon} />
                        Experienced multi-language team of NetScaler experts
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CheckIcon className={classes.checkIcon} />
                        Fully managed TLS Certificates included
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CheckIcon className={classes.checkIcon} />
                        Easy and secure onboarding
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CheckIcon className={classes.checkIcon} />
                        Application deployment best practices
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item>
                <a
                  href="https://www.arrow.com/globalecs/uk/services/managed-services/netscaler/"
                  className={classes.buttonLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className={classes.homeButton}>
                    More Information
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
HomePage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(HomePage));
