/* eslint-disable no-console */
export const GENERATE_DOC = 'GENERATE_DOC';

export function generateDoc(payload) {
  console.log('action: Generate_doc');
  return {
    type: GENERATE_DOC,
    payload,
  };
}
