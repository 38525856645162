// Created by Blubird - Frank Vandebergh
/* eslint no-undef: 0 */
/* eslint-disable */ 
(function(mod) {
  'use strict';
  if (typeof exports == 'object' && typeof module == 'object') // CommonJS
    mod(require("../../../node_modules/codemirror/lib/codemirror"));
    
  else if (typeof define == 'function' && define.amd) // AMD
    define(['../../../node_modules/codemirror/lib/codemirror'], mod);
  else // Plain browser env
    mod(window.CodeMirror);
})(function(CodeMirror) {
'use strict';

CodeMirror.defineMode('netscaler', function() {
  function buildRegexp(patterns, options) {
    options = options || {};
    var prefix = options.prefix !== undefined ? options.prefix : '^';
    var suffix = options.suffix !== undefined ? options.suffix : '\\b';

    for (var i = 0; i < patterns.length; i++) {
      if (patterns[i] instanceof RegExp) {
        patterns[i] = patterns[i].source;
      }
      else {
        patterns[i] = patterns[i].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      }
    }

    return new RegExp(prefix + '(' + patterns.join('|') + ')' + suffix, 'i');
  }

  var notCharacterOrDash = '(?=[^A-Za-z\\d\\-\/_]|$)';
  var space = '\s';

  var varNames = /[\w\-:]/
  var keywords = buildRegexp([
    /channel|rdpproxy|rep|ulfd|certaction|trafficaction|trafficDomain|netprofile|pbrs/,
    /url|portaltheme|localpolicy|certpolicy|trafficpolicy|reputation|settings|ocspresponder|disable/,
    /aaa|route|nd6RAvariables|manager|tacacsAction|param|subscriber|suffix|pbr|tm|responder|rewrite|vpn/,
    /interface|ns|audit|ip6|system|rsskeytype|lacp|vip|vlan|SP|PQ|AppQoE|snmp|ipsec|nd6RAvariables|ssl/,
    /server|authentication|authorization|lb|cmp|L3Param|appfw|expression|ip6TunnelParam|ptp|dns|cache|HA/,
    /servicegroup|AppFlow|CH|CF|WL|CS|CR|IC|SSLVPN|FR|L3|MBF|Edge|USNIP|PMTUD|rdp|ca|service|ip|ip6|vserver/,
    /policy|action|group|user|hostName|alarm|community|parameter|trap|option|tcpProfile|certkey|cipher/,
    /encryptionParams|global|nsRec|addRec|config|feature|mode|profile|radiusAction|syslogAction|cachegroup/,
    /messageaction|node|sessionPolicy|rpcNode|monitor|sessionAction|policylabel|syslogPolicy|TCP|HTTP/,
    /preauthenticationaction|httpprofile|patset|contentGroup|radiusPolicy|nameserver|diameter|transform/,
    /formSSOAction|negotiatePolicy|mib|stringmap|extension|sessionParameter|syslogParams|nslogParams/,
    /ldapAction|tcpbufparam|tcpParam|serverprofile|preauthenticationpolicy|servicegroup|ldapPolicy|superuser/
  ], { prefix: '', suffix : notCharacterOrDash});
  //suffix: notCharacterOrDash });
//], { prefix: '', suffix: '' });

  //var punctuation = /[\[\]{},;`\.]|@[({]/;
  var wordOperators = buildRegexp([
    /add|bind|set|remove|rm|show|sh|unbind|enable|link|apply/
  ], { prefix: '' });

  var nsParameters = buildRegexp([
    /portaltheme|loginonce|listenpolicy|vserverfqdn|snicert|domainresolveretry|group|authenticationhost/,
    /storagetype|maxpktpermss|sessionsocketlifetime|q2label|q3label|q4label|td|snmp|persistentCookie/,
    /encryptmethod|usernamefield|basetheme|proxyserver|proxyport|backuplbmethod|srcippersistency/,
    /targetvserverexpr|templateresfresh|httpcookie|httpreferer|httpurl|haHeartbeat|userField/,
    /collectors|starturlacrtion|denyurlacrtion|responsecontenttype|denyurl|starturl|domainuser/,
    /xmldosurl|xmlvalidationurl|requrlfrom|lbvserver|ssl3|snienable|urlname|lacpMode|flavor|appFlowExport/,
    /sslProfileType|tls11|tls12|connFlushIfNoMem|dns64Timeout|lacpKey|lamac|tagall|ifAlias|icaOnly/,
    /lrMinThroughput|gui|restrictAccess|arp|tagged|allPartitions|sendBuffSize|mss|rstMaxAck|targetVserver/,
    /spoofSynDrop|maxBurst|initialCwnd|oooQSize|pktPerRetx|minRTO|denySSLReneg|clientAuth|clientCert/,
    /sslRedirect|tls11|tls12|tacacsSecret|persistenceBackup|backupPersistenceTimeout|backupVServer/,
    /tcpProfileName|dateFormat|tcp|userDefinedAuditLog|pinned|insertAge|priority|targetLBVserver/,
    /TTL|sslProfile|cipherPriority|timezone|policy|gslb|sp|strongPassword|doppler|customHeaders|dh|dhFile/,
    /secure|state|throughput|bandwidthHigh|bandwidthHigh|bandwidthNormal|intftype|ifnum|vlan|type/,
    /mgmtAccess|dynamicRouting|vServer|scope|rsstype|lifetime|time|communityName|snmpTrapLogging|snmpset/,
    /WS|SACK|nagle|frto|sessTimeout|sessReuse|staServer|method|eccCurveName|httpPort|dnsVserverName/,
    /authorizationGroup|defaultAuthorizationAction|clientIdleTimeout|proxy|clientOptions|clientConfiguration/,
    /SSO|icaProxy|wihome|aliasname|wiPortalMode|iipDnsSuffix|clientlessVpnMode|WS|clientlessModeUrlEncoding/,
    /UITHEME|intranetIP|passcrypt|key|ssl3|IPAddress|natPcbForceFlushLimit|sysPriority|srcIP|cert|via|isRegex/,
    /keyValue|eRSA|CustomServerID|httpRequest|authTimeout|comment|policyName|userName|serverPort|ldapBase/,
    /ldapBindDn|ldapBindDnPassword|encrypted|ldapLoginName|groupAttrName|subAttributeName|secType|inform/,
    /realm|identity|rule|queryType|accounting|persistenceType|splitDns|sessionsThreshold|monitorName|dispatcherIP/,
    /invoke|type|relExpiry|weakNegRelExpiry|maxResSize|memLimit|query|scriptname|dispatcherPort|password|LRTM/,
    /destPort|baseDN|homepage|authentication|splitTunnel|localLanAccess|rfc1918|transparentInterception|formSSOAction/,
    /defaultAuthorizationAction|clientIdleTimeout|proxy|allProtocolProxy|proxyException|clientCleanupPrompt/,
    /forceCleanup|applicationdata|autocomplete|SSO|ssoCredential|clientDebug|homePage|icaProxy|ClientChoices/,
    /forcedTimeout|hamonitor|netmask|forcedTimeoutWarning|ntDomain MSAD|clientlessVpnMode|type|allowedLoginGroups/,
    /interval|filter|action|logaction|evaluates|storeInGroup|maxAAAUsers|lbMethod|loglevel|mac|resptimeout/,
    /respcode|retries|charset|index|externalCache|reqTimeout|radkey-priority|gotoPriorityExpression|cipherName/,
    /certkeyName|icmpErrGenerate|severity|thresholdValue|timeout|encrypted|maxClient|maxReq|cip|usip|useproxyport/,
    /enableEnhancedAuthFeedback|holdOnSubscriberAbsense|ntDomain|cacheable|domain|actionURL|appTimeout|destIP/,
    /pcrfRealm|holdOnSubscriberAbsense|quantumSize|clientSecurityLog|sessionTicketLifeTime|expiryMonitor|recv/,
    /holdOnSubscriberAbsence|revalidationTimeout|servicePathAVP|servicePathVendorid|storename|authenticationDomain/,
    /encryptTriggerPktCount|pushEncTriggerTimeout|contact|name|location|customID|healthMonitor|serverName|reqUrlInto|reqUrlFrom/,
    /enableSessionStickiness|httpOnlyCookie|returnType|httpMethod|hostExpr|defaultProfile|sslTriggerTimeout/,
    /minpasswordlen|nextHop|promptString|externalAuth|returnType|cookiePassphrase|radKey|ListenPriority|resUrlInto|resUrlFrom/,
    /httpHost|securityInsight|startURLAction|denyURLAction|startURLClosure|streaming|fieldFormatAction|URLRewritePolicyLabel/,
    /fieldConsistencyAction|RefererHeaderCheck|CSRFtagAction|crossSiteScriptingAction|SQLInjectionAction|pattern/,
    /bufferOverflowMaxURLLength|XMLSQLInjectionAction|XMLXSSAction|XMLWSIAction|XMLDoSAction|XMLFormatAction/,
    /XMLAttachmentAction|errorURL|excludeResContentType|XMLAttachmentURL|XMLMaxElementDepthCheck|XMLMaxelementNameLengthCheck/,
    /XMLMaxElementsCheck|XMLMaxAttributesCheck|XMLMaxAttributeNameLengthCheck|XMLMaxAttributeValueLengthCheck/,
    /XMLValidateSOAPEnvelope|XMLWSIURL|XMLWSIChecks|maxTimeWaitConn|send|httpProfileName|haStatus|webSocket/,
    /domainUserPasswd|passwdField|kcdAccount|InitiateLogout|ssoSuccessRule|authn401|authnVsName|responseStatusCode|reasonPhrase/,
    /cltTimeout|svrTimeout|CKA|TCPB|CMP|appflowLog|logtoNewnslog|forceCleanup|serverIP|search|undefAction|CA|ocspCheck|failsafe/,
    /realmStr|enterpriseRealm|delegatedUser|threshold|timeSlice|matchWildcardtoany|format|selectorName|samlSpCertName/,
    /samlIdPCertName|authenticationSchema|SSOCredentials|passwdExpression|passwordCredentialIndex|authnProfile|aaaSessionLogLevel/,
    /weight|port|hashId|url|cache|ocspResponder|cacheTimeout|trustResponder|loginSchema|nextFactor|protocol|kernelstate/,
    /deviation|downTime|hashmethod|inc|helloVerifyRequest/
   

  ], { prefix: '-'});



  //var symbolOperators = /[+\-*\/%]=|\+\+|--|\.\.|[+\-*&^%:=!|\/]|<(?!#)|(?!#)>/;

  var operators = buildRegexp([wordOperators], { suffix: notCharacterOrDash });

  var numbers = /^((0x[\da-f]+)|((\d+\.\d+|\d\.|\.\d+|\d+)(e[\+\-]?\d+)?))[ld]?([kmgtp]b)?/i;
  var numbers2= buildRegexp([numbers],{ suffix: notCharacterOrDash});


  var identifiers = /^[A-Za-z0-9\_\.\+\*\:=/][A-Za-z0-9\-\_\:=\+\d]*/;

  //var symbolBuiltins = /[A-Z]:|%|\?/i;
  



  var builtins = buildRegexp([nsParameters], { suffix: notCharacterOrDash });

  var grammar = {
    keyword: keywords,
    number: numbers2,
    operator: operators,
    builtin: builtins,
   // punctuation: punctuation,
    identifier: identifiers
  };

  // tokenizers
  function tokenBase(stream, state) {
    // Handle Comments
    //var ch = stream.peek();

    var parent = state.returnStack[state.returnStack.length - 1];
    if (parent && parent.shouldReturnFrom(state)) {
      state.tokenize = parent.tokenize;
      state.returnStack.pop();
      return state.tokenize(stream, state);
    }

    if (stream.eatSpace()) {
      return null;
    }



  // special case quoted string with q{ }
    if (stream.eat('q')){
      var pos = stream.pos;
      var current = stream.string.charAt(pos);  
      if(current === "{"){
        return tokenSpecialQuoteString(stream, state);
      }
      if(current === "<"){
        return tokenSpecialQuoteString2(stream, state);
      }
      if(current === "/"){
        return tokenSpecialQuoteString3(stream, state);
      }
  }
   

    for (var key in grammar) {
      if (stream.match(grammar[key])) {
        return key;
      }
    }






    var ch = stream.next();


    



    // double-quote string
    if (ch === '"') {
      return tokenDoubleQuoteString(stream, state);
    }



    if (ch === '#') {
      stream.skipToEnd();
      return 'comment';
    }

    // if (ch === '@') {
    //   var quoteMatch = stream.eat(/["']/);
    //   if (quoteMatch && stream.eol()) {
    //     state.tokenize = tokenMultiString;
    //     state.startQuote = quoteMatch[0];
    //     return tokenMultiString(stream, state);
    //   } else if (stream.eol()) {
    //     return 'error';
    //   } else if (stream.peek().match(/[({]/)) {
    //     return 'punctuation';
    //   } else if (stream.peek().match(varNames)) {
    //     // splatted variable
    //     return tokenVariable(stream, state);
    //   }
    // }
    return 'error';
   }

  function tokenSingleQuoteString(stream, state) {
    var ch;
    while ((ch = stream.peek()) != null) {
      stream.next();

      if (ch === "'" && !stream.eat("'")) {
        state.tokenize = tokenBase;
        return 'string';
      }
    }

    return 'error';
  }
  function tokenSpecialQuoteString(stream, state) {
    var ch;
    var prevch;
    while ((ch = stream.peek()) != null) {

      stream.next();
      
  
      if (ch === '}' && prevch != "\\" && !stream.eat('}')) {
        state.tokenize = tokenBase;
        return 'string';
      }
      prevch = ch;
    }

    return 'error';
  }
  function tokenSpecialQuoteString2(stream, state) {
    var ch;
    var prevch;
    while ((ch = stream.peek()) != null) {

      stream.next();
      
  
      if (ch === '>' && prevch != "\\" && !stream.eat('>')) {
        state.tokenize = tokenBase;
        return 'string';
      }
      prevch = ch;
    }

    return 'error';
  }
  function tokenSpecialQuoteString3(stream, state) {
    var ch;
    var prevch;
    while ((ch = stream.peek()) != null) {

      stream.next();
      
  
      if (ch === '/' && prevch != "\\" && !stream.eat('>')) {
        state.tokenize = tokenBase;
        return 'string';
      }
      prevch = ch;
    }

    return 'error';
  }

  function tokenDoubleQuoteString(stream, state) {
    var ch;
    var prevch;
    while ((ch = stream.peek()) != null) {

      stream.next();
      
  
      if (ch === '"' && prevch != "\\" && !stream.eat('"')) {
        state.tokenize = tokenBase;
        return 'string';
      }
      prevch = ch;
    }

    return 'error';
  }

  function tokenStringInterpolation(stream, state) {
    return tokenInterpolation(stream, state, tokenDoubleQuoteString);
  }

  function tokenMultiStringReturn(stream, state) {
    state.tokenize = tokenMultiString;
    state.startQuote = '"'
    return tokenMultiString(stream, state);
  }

  function tokenHereStringInterpolation(stream, state) {
    return tokenInterpolation(stream, state, tokenMultiStringReturn);
  }

  function tokenInterpolation(stream, state, parentTokenize) {
    if (stream.match('$(')) {
      var savedBracketNesting = state.bracketNesting;
      state.returnStack.push({
        /*jshint loopfunc:true */
        shouldReturnFrom: function(state) {
          return state.bracketNesting === savedBracketNesting;
        },
        tokenize: parentTokenize
      });
      state.tokenize = tokenBase;
      state.bracketNesting += 1;
      return 'punctuation';
    } else {
      stream.next();
      state.returnStack.push({
        shouldReturnFrom: function() { return true; },
        tokenize: parentTokenize
      });
      state.tokenize = tokenVariable;
      return state.tokenize(stream, state);
    }
  }

  function tokenComment(stream, state) {
    var maybeEnd = false, ch;
    while ((ch = stream.next()) != null) {
      if (maybeEnd && ch == '>') {
          state.tokenize = tokenBase;
          break;
      }
      maybeEnd = (ch === '#');
    }
    return 'comment';
  }

  function tokenVariable(stream, state) {
    var ch = stream.peek();
    if (stream.eat('{')) {
      state.tokenize = tokenVariableWithBraces;
      return tokenVariableWithBraces(stream, state);
    } else if (ch != undefined && ch.match(varNames)) {
      stream.eatWhile(varNames);
      state.tokenize = tokenBase;
      return 'variable-2';
    } else {
      state.tokenize = tokenBase;
      return 'error';
    }
  }

  function tokenVariableWithBraces(stream, state) {
    var ch;
    while ((ch = stream.next()) != null) {
      if (ch === '}') {
        state.tokenize = tokenBase;
        break;
      }
    }
    return 'variable-2';
  }

  function tokenMultiString(stream, state) {
    var quote = state.startQuote;
    if (stream.sol() && stream.match(new RegExp(quote + '@'))) {
      state.tokenize = tokenBase;
    }
    else if (quote === '"') {
      while (!stream.eol()) {
        var ch = stream.peek();
        if (ch === '$') {
          state.tokenize = tokenHereStringInterpolation;
          return 'string';
        }

        stream.next();
        if (ch === '`') {
          stream.next();
        }
      }
    }
    else {
      stream.skipToEnd();
    }

    return 'string';
  }

  var external = {
    startState: function() {
      return {
        returnStack: [],
        bracketNesting: 0,
        tokenize: tokenBase
      };
    },

    token: function(stream, state) {
      return state.tokenize(stream, state);
    },
    lineComment: '#',
    fold: 'brace'
  };
  return external;
});

CodeMirror.defineMIME('application/x-netscaler', 'netscaler');
});
