export const CHECK_LOGIN = 'CHECK_LOGIN';

export function checkLogin(payload, attributes) {
  const newpayload = payload;
  newpayload.attributes = attributes;
  return {
    type: CHECK_LOGIN,
    payload: newpayload,
  };
}
