import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    flexGrow: 1,
  },
};

class ShareStep1Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareValidity: 0,
      shareMaskIP: false,
      shareDisableAnalysis: false,
    };
  }

  handleValidityChange = (e) => {
    this.setState({ shareValidity: e.target.value });
  };

  handleMaskIPChange = (e) => {
    this.setState({ shareMaskIP: e.target.checked });
  };

  handleDisableAnalysisChange = (e) => {
    this.setState({ shareDisableAnalysis: e.target.checked });
  };

  handleClose = (next) => {
    const { onClose: onCloseAlt } = this.props;
    const { shareValidity, shareMaskIP, shareDisableAnalysis } = this.state;
    onCloseAlt(next, shareValidity, shareMaskIP, shareDisableAnalysis);
    this.setState({
      shareValidity: 0,
      shareMaskIP: false,
      shareDisableAnalysis: false,
    });
  };

  render() {
    const { ...other } = this.props;
    const { shareValidity, shareMaskIP, shareDisableAnalysis } = this.state;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="Share Configuration Snippet"
        fullWidth={false}
        {...other}
      >
        <DialogTitle id="form-dialog-title">Share Configuration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose privacy and retention options for sharing this configuration
          </DialogContentText>
          <table>
            <tr>
              <td>
                <InputLabel id="demo-simple-select-label">Retention</InputLabel>
              </td>
              <td>
                {' '}
                <Select
                  labelId="demo-simple-select-label"
                  id="share-validity"
                  value={shareValidity}
                  onChange={this.handleValidityChange}
                >
                  <MenuItem value={1}>1 Week</MenuItem>
                  <MenuItem value={2}>2 Weeks</MenuItem>
                  <MenuItem value={4}>1 Month</MenuItem>
                  <MenuItem value={24}>6 Months</MenuItem>
                  <MenuItem value={0}>Unlimited</MenuItem>
                </Select>
              </td>
            </tr>
            <tr>
              <td>
                <InputLabel id="demo-simple-select-label">
                  Hide Sensitive Info
                </InputLabel>
              </td>
              <td>
                <FormControlLabel
                  disabled
                  control={<Switch checked />}
                  label="(always active)"
                />
              </td>
            </tr>
            <tr>
              <td>
                <InputLabel id="demo-simple-select-label">
                  Mask IP Addresses
                </InputLabel>
              </td>
              <td aria-label="mask IP">
                <Switch
                  id="share-maskIP"
                  name="checkedB"
                  color="primary"
                  value={shareMaskIP}
                  onChange={this.handleMaskIPChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <InputLabel id="demo-simple-select-label">
                  Disable Config Analysis
                </InputLabel>
              </td>
              <td aria-label="disable analysis">
                <Switch
                  id="share-disableanalysis"
                  name="checkedC"
                  color="primary"
                  value={shareDisableAnalysis}
                  onChange={this.handleDisableAnalysisChange}
                />
              </td>
            </tr>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose(true)} color="secondary">
            Next Step
          </Button>
          <Button onClick={() => this.handleClose(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
ShareStep1Dialog.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
};

export default withStyles(styles)(ShareStep1Dialog);
