import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { getConfig } from 'xpand-parser-netscaler';
import { updateFilter } from '../../actions/updateFilter';
import { ApiSharedSnippetLoad } from '../../actions/ApiSharedSnippet';

import CodeEditor from '../codeEditor';
import FilterBar from '../FilterBar';
import { withParser } from '../../ParserContext';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ApiSharedSnippetLoad, updateFilter }, dispatch);
}

const mapStateToProps = (state) => ({
  configFiltered: state.config.configFiltered,
  activeLine: state.config.activeLine,
  configLoadError: state.config.configLoadError,
  configLoadErrorDetail: state.config.configLoadErrorDetail,
});

class Viewer extends Component {
  constructor(props) {
    super(props);

    this.addDependencyFilter = this.addDependencyFilter.bind(this);
    this.addObjectTypeFilter = this.addObjectTypeFilter.bind(this);

    this.state = {
      mode: props.mode,
    };
  }

  componentDidMount() {
    const { mode } = this.state;
    const { ApiSharedSnippetLoad: ApiSharedSnippetLoadAlt, parse } = this.props;
    // load snippet based on argument
    if (mode === 'snippet') {
      const { id: sharedSnippetId } = this.props;
      ApiSharedSnippetLoadAlt({ sharedSnippetId }, parse);
    }
  }

  addObjectTypeFilter() {
    const {
      configFiltered,
      activeLine,
      updateFilter: updateFilterAlt,
    } = this.props;
    //             // find original object for this line
    if (configFiltered) {
      if (configFiltered.objects) {
        for (
          let i = 0;
          i < Object.keys(configFiltered.objects).length;
          i += 1
        ) {
          const objecttype = Object.keys(configFiltered.objects)[i];
          const objectitems = configFiltered.objects[objecttype].items;

          for (let j = 0; j < objectitems.length; j += 1) {
            const curline = objectitems[j].baseline;

            if (curline.toString().trim() === activeLine.toString().trim()) {
              const newfilter = {};
              newfilter.filtertype = 'type';
              newfilter.filtervalue = objecttype;

              updateFilterAlt({ replace: true, newfilter });
              return;
            }
          }
        }
      }
    }
  }

  addDependencyFilter() {
    const {
      configFiltered,
      activeLine,
      updateFilter: updateFilterAlt,
    } = this.props;

    if (configFiltered) {
      if (configFiltered.objects) {
        for (
          let i = 0;
          i < Object.keys(configFiltered.objects).length;
          i += 1
        ) {
          const objecttype = Object.keys(configFiltered.objects)[i];
          const objectitems = configFiltered.objects[objecttype].items;

          for (let j = 0; j < objectitems.length; j += 1) {
            const curline = objectitems[j].baseline;

            if (curline.toString().trim() === activeLine.toString().trim()) {
              const conffile = getConfig();
              const configitem = conffile.parseconfig.find(
                (x) => x.objecttype === objecttype,
              );
              const newfilter = {};
              newfilter.filtertype = objecttype;
              newfilter.filtervalue = objectitems[j][configitem.key];

              updateFilterAlt({ replace: true, newfilter });

              return;
            }
          }
        }
      }
    }
  }

  renderConfFile() {
    const { configFiltered, configLoadError, configLoadErrorDetail } =
      this.props;
    const codeString = '# Config Snippet generated on xconfig.io';
    let exportlines = [];

    if (configLoadError) {
      return (
        <div>
          <h2>
            There was an error loading this config: {configLoadErrorDetail}
          </h2>
        </div>
      );
    }

    if (configFiltered && configFiltered.objects) {
      for (let i = 0; i < Object.keys(configFiltered.objects).length; i += 1) {
        const objecttype = Object.keys(configFiltered.objects)[i];
        const objectitems = configFiltered.objects[objecttype].items;

        //   for( let j = 0; j < objectitems.length;j++){
        //      var item = objectitems[j];

        // }
        exportlines = exportlines.concat(objectitems);
      }

      // sort on line number
      const sorted = exportlines.sort((a, b) => (a.line > b.line ? 1 : -1));
      sorted.unshift({ line: 0, guid: '000', baseline: codeString });

      const code = sorted
        .map((item) => {
          if (item && item.baseline) {
            return item.baseline;
          }
          return '';
        })
        .join('\n');

      return (
        <div>
          <FilterBar />
          <CodeEditor
            code={code}
            uid="viewer"
            height="100%"
            addDependencyFilter={this.addDependencyFilter}
            addObjectTypeFilter={this.addObjectTypeFilter}
          />
        </div>
      );
    }
    return <code />;
  }

  render() {
    return (
      <div className="viewer">{this.renderConfFile()}</div>
      /*             <Col s={1} m={8} className="viewer">
                
            </Col> 
           
                {this.renderConfFile}
            */
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withParser(Viewer));
